import React, {useEffect, useState} from 'react';
import {Card, Space, Image, Avatar, Modal, DatePicker, Select} from 'antd';
import './index.css';
import { CaretRightOutlined, FunnelPlotOutlined, AntDesignOutlined } from '@ant-design/icons';
import { Form, Button, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Input, Container, Row, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {
    chargePointBody,
    chargerBody,
    getChargePointBasedOnTextSearchBody,
    getChargePointBasedOnTextSearchResponse
} from "../../../types/admin";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {ChargePointReport} from "../ChargePointReport";


const Report: React.FC = ({ children }) => {

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const [selectedChargePointId, setSelectedChargePointId] = useState("")

    const [chargePointList, setChargePointList] = useState<getChargePointBasedOnTextSearchBody[]>();

    const [ActiveTab, setActiveTab] = useState("settings");

    const { RangePicker } = DatePicker;
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onGetChargePointList = () => {
        axios.get("/api/chargePointsLocationSocketStatusSummary?page=0&pageSize=1000", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: getChargePointBasedOnTextSearchResponse = res.data;
            setChargePointList(response.data);
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        })
    }

    useEffect(() => {
        onGetChargePointList()
    }, []);


    return (
        <div>
            <span className='page-title'>Charge Point Report</span>
            <div style={{margin: "10px 0px",}}>
                <Form.Item
                    label={"Charge Point"}
                >
                    <Select onChange={(value) => {
                        setSelectedChargePointId(value)
                    }}>
                        {
                            chargePointList?.map((chargePointDetails: getChargePointBasedOnTextSearchBody) => {
                                return (
                                    <Select.Option key={chargePointDetails.id.id} value={chargePointDetails.id.id}>{chargePointDetails.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
            </div>

            {
                selectedChargePointId !== "" && <ChargePointReport chargePointId={selectedChargePointId} />
            }
        </div>
    )
}

export default Report
