import React, {useEffect, useState} from 'react';
import {Card, Col, Input, Row, Space, Image, Pagination, Modal} from 'antd';
import './index.css';
import {
    PicLeftOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    StepBackwardOutlined
} from '@ant-design/icons';
import {
    useNavigate
} from "react-router-dom";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import axios from "axios";
import {
    chargePointBody,
    chargePointListType,
    chargePointListTypeWithImage,
    getVendorResponse
} from "../../../types/admin";
import {PopNotification} from "../../../shared/notification";
import {cpus} from "os";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";

const {Search} = Input;

const ChargePoints: React.FC = ({children}) => {

    const {getGroups, token} = useCredentialStore(({getGroups, token}) => ({
        getGroups,
        token
    }));

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [chargePointDetails, setChargePointDetails] = useState<chargePointListType>();
    const [textSearch, setTextSearch] = useState('');
    const [loading, setLoading] = useState<any>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedChargePointToDelete, setSelectedChargePointToDelete] = useState<{ id: string, name: string, city: string | null }>();
    const onSearch = (value: string) => setTextSearch(value);
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {

        axios
            .delete(`/api/chargePoint/${selectedChargePointToDelete?.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                PopNotification("success", "Charge Point Deleted Successfully");
                getChargePointsList();

            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setIsModalVisible(false);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getChargePointsList = async () => {
        setLoading(true)
        axios
            .get(textSearch === '' ? `/api/chargePointsLocationSocketStatusSummary?pageSize=${pageSize}&page=${page}` : `/api/chargePointsLocationSocketStatusSummary?pageSize=${pageSize}&page=${page}&textSearch=${textSearch}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(async (res) => {
                setChargePointDetails(res.data)
                setLoading(false);

            })
            .catch((error) => {
                console.log(error)
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getChargePointsList();
    }, [page, pageSize, textSearch]);

    return (
        <div>
            <span className='page-title'>Charge Points</span>
            <Row>
                <Col md={24}>
                    <Input
                        placeholder="input search text"
                        allowClear
                        onChange={(e) => {
                            onSearch(e.target.value)
                        }}
                        size='large'
                    />
                </Col>
            </Row>
            {
                loading ? <LoadingSpinner/> : (
                    <>
                        <Row gutter={[16, 16]} style={{marginTop: 20,}}>
                            {
                                chargePointDetails?.data.map((chargePointDetail, index) => (
                                    <Col md={12}>
                                        <Card
                                            size="small"
                                            style={{backgroundColor: "#999797", borderRadius: 12, cursor: "pointer"}}>
                                            <h6 style={{color: 'white'}} onClick={() => {
                                                navigate(`/charge-point/details?chargePointId=${chargePointDetail.id.id}&cityName=${chargePointDetail.location.city}`, {replace: false});
                                            }}>{chargePointDetail.name} | {`${chargePointDetail.location.city}`}</h6>
                                            {
                                                getGroups() === "SYS_ADMIN" && (
                                                    <div style={{position: "absolute", right: 40, top: 10}}>
                                                        <DeleteOutlined
                                                            style={{fontSize: '150%', color: 'white'}}
                                                            onClick={() => {
                                                                setSelectedChargePointToDelete({
                                                                    id: chargePointDetail.id.id,
                                                                    name: chargePointDetail.name,
                                                                    city: chargePointDetail.location.city
                                                                })
                                                                showModal()
                                                            }}/>
                                                    </div>
                                                )
                                            }
                                            <div style={{position: "absolute", right: 10, top: 10}}>
                                                <SettingOutlined style={{fontSize: '150%', color: 'white'}}
                                                                 onClick={() => {
                                                                     navigate(`/charge-point-settings?chargePointId=${chargePointDetail.id.id}`, {replace: false});
                                                                 }}/>
                                            </div>
                                            <div style={{position: "absolute", right: 70, top: 10}}>
                                                <PicLeftOutlined style={{fontSize: '150%', color: 'white'}}
                                                                 onClick={() => {
                                                                     navigate(`/event-log?chargePointId=${chargePointDetail.id.id}&chargePointName=${chargePointDetail.name}&cityName=${chargePointDetail.location.city}`, {replace: false});
                                                                 }}/>
                                            </div>
                                            <hr style={{border: '1px solid white', color: 'white'}}/>
                                            <div onClick={() => {
                                                navigate(`/charge-point/details?chargePointId=${chargePointDetail.id.id}&cityName=${chargePointDetail.location.city}`, {replace: false});
                                            }}>
                                                <Row gutter={[16, 16]}>
                                                    <Col md={10}>
                                                        <Image
                                                            style={{maxHeight: "20vh"}}
                                                            src={
                                                                chargePointDetail.imageId.id === null ?
                                                                    require("../../../assets/images/default_pic.PNG")
                                                                    : `/api/image/` + chargePointDetail.imageId.id
                                                            }
                                                            preview={false}
                                                        />
                                                    </Col>
                                                    <Col md={14}>
                                <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#C4C4C4"
                                    }}>{chargePointDetail.socketStatusSummary.total}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Charging Socket</span>
                                </span>
                                                        <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#47B02D"
                                    }}>{chargePointDetail.socketStatusSummary.unoccupied}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Unoccupied</span>
                                </span>
                                                        <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#DE4545"
                                    }}>{chargePointDetail.socketStatusSummary.occupied}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Occupied</span>
                                </span>
                                                        <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#E98F26"
                                    }}>{chargePointDetail.socketStatusSummary.reserved}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Reserved</span>
                                </span>
                                                        <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#D2F019"
                                    }}>{chargePointDetail.socketStatusSummary.underMaintenance}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Under maintenance</span>
                                </span>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                        <span style={{display: "block", textAlign: "right", marginTop: 20,}}>
                            <span style={{display: "block"}}>
                                <Pagination defaultCurrent={1} total={chargePointDetails?.totalPages ?? 1}/>
                            </span>
                        </span>
                    </>
                )
            }

            <Modal
                title="Delete Charge Point"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Confirm"
                cancelText="Cancel"
            >
                <p>Are you sure want to delete charge point? </p>
                <p>Name: {selectedChargePointToDelete?.name}</p>
                <p>City: {selectedChargePointToDelete?.city} </p>
            </Modal>

        </div>
    )
}

export default ChargePoints
