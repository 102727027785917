import {Button, Modal, Table} from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import axios from "axios";
import {useCredentialStore} from "../../../../hooks/useCredentialsStore";

export const ECoinLogModal = ({isModalOpen, setIsModalOpen, userId, flag}: { isModalOpen: boolean, setIsModalOpen: any, userId: string, flag: boolean }) => {

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const [logList, setLogList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalElements, setTotalElements] = React.useState(0);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onGetLogList = () => {
        setLoading(true);
        axios.get(`/api/user/${userId}/e-coin/transactions?page=${currentPage}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                const logList = res.data;
                setLogList(logList.data);
                setTotalElements(logList.totalElements);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        onGetLogList();
    }, [currentPage, pageSize, flag]);

    const columns = [
        {
            title: "No.",
            dataIndex: "index",
            key: 'index',
            render: (text: string, record: any, index: number) => {
                return (currentPage) * pageSize + index + 1
            }
        },
        {
            title: 'Date Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (text: string, record: any, index: number) => {
                return <div>{moment(+text).format('YYYY-MM-DD HH:mm:ss')}</div>;
            }
        },
        {
            title: 'Transaction Type',
            dataIndex: 'type',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance'
        }
    ]

    return (
        <>
            <Button type="primary" onClick={showModal}>
                E Coin Log
            </Button>
            <Modal title="Log" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Table
                    dataSource={logList}
                    columns={columns}
                    pagination={{
                        total: totalElements,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page - 1);
                            setPageSize(pageSize);
                        }
                    }}
                />
            </Modal>
        </>

    )
}
