import React, {useState} from 'react';
import {Card, Space, Image, Avatar, Checkbox} from 'antd';
import './index.css';
import {CaretRightOutlined, FunnelPlotOutlined, AntDesignOutlined} from '@ant-design/icons';
import {Form, Button, Radio} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Col, Input, Container, Row} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {Upload, message} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {getBase64} from "../../../utils/image";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {addChargePointProps, createAdminProps} from "../../../types/superAdmin";

import {LoadingSpinner} from "../../../shared/LoadingSpinner";

const {Dragger} = Upload;

const Home: React.FC = ({children}) => {

    const [isPublic, setIsPublic] = useState<boolean>(true);
    const [isOperative, setIsOperative] = useState<boolean>(true);
    const [profileImageId, setProfileImageId] = useState<string | null>(null);
    const [selectedPriceMode, setSelectedPriceMode] = useState<string>("RATE");
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { proxy } = require("../../../../package.json");
    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const [initRate, setInitRate] = useState<any>(0);
    const [initTariff, setInitTariff] = useState<any>(0);
    const [initPrivilegeRate, setInitPrivilegeRate] = useState<any>(0);
    const [initIdleFee, setInitIdleFee] = useState<any>(0);
    const [initBufferTime, setInitBufferTime] = useState<any>(0);

    const onAddChargePoint = () => {
        form.validateFields().then(values => {
            setLoading(true);
            const dataToSubmit: addChargePointProps = {
                isOperative: isOperative,
                isPublic: isPublic,
                location: {
                    address1: values.address1,
                    address2: values.address2,
                    city: values.city,
                    latitude: values.latitude,
                    longitude: values.longitude,
                    postalCode: parseInt(values.postalCode),
                    state: values.state
                },
                imageId: {
                    id: profileImageId
                },
                method: selectedPriceMode,
                name: values.name,
                privilegeRate: parseFloat(values.privilegeRate),
                rate: (values.rate === null || values.rate === "" || values.rate === undefined) ? 0 : parseFloat(values.rate),
                tariff: (values.tariff === null  || values.tariff === "" || values.tariff === undefined) ? 0 : parseFloat(values.tariff),
                idleFee: parseFloat(values.idleFee?? '0'),
                bufferTime: parseFloat(values.bufferTime?? '0')
            }

            console.log(dataToSubmit)

            axios
                .post("/api/chargePoint", dataToSubmit, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    PopNotification("success", "Charge Point Created Successfully");
                })
                .catch((error) => {
                    PopNotification("error", error.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }

    return (
        <div>
            <span className='page-title'>Add new charge point</span>
            <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onAddChargePoint}
                >
                    <Row>
                        <Col md={6}>
                            <div style={{marginTop: 20,}}>
                                {/*<span className='heading-page'>Charge Point Profile Image</span>*/}
                                <Upload
                                    accept="image/png, image/jpeg"
                                    maxCount={1}
                                    // listType="picture-card"
                                    showUploadList={false}
                                    customRequest={(file: any) => {
                                        console.log(file)
                                        const isLt2M = file.file.size / 1024 / 1024 / 1024 < 500;
                                        if (isLt2M) {
                                            getBase64(file.file).then((base64String: any) => {
                                                const dataToSubmit = {
                                                    base64: base64String,
                                                }

                                                axios
                                                    .post("/api/image", dataToSubmit, {
                                                        headers: {
                                                            Authorization: `Bearer ${token}`
                                                        }
                                                    })
                                                    .then((res) => {
                                                        setProfileImageId(res.data.id.id)
                                                        PopNotification("success", "Profile Image Uploaded");
                                                    })
                                                    .catch((error) => {
                                                        PopNotification("error", error.response.data.message);
                                                    })
                                            });

                                        } else {
                                            PopNotification("error", "Image size should not be greater than 500kb size");
                                        }

                                    }} // to override the component sending request on image upload, see https://stackoverflow.com/a/51519603/4858751
                                    beforeUpload={() => console.log("before upload")} // see https://ant.design/components/upload/#components-upload-demo-avatar
                                    onChange={() => console.log("handle change")} // see https://ant.design/components/upload/#components-upload-demo-avatar
                                >
                                    {profileImageId ? (
                                        <Image
                                            preview={false}
                                            width={200}
                                            src={`/api/image/` + profileImageId}
                                        />
                                    ) : <Button block type="primary" className="login-form-button">Upload Profile
                                        Image</Button>}
                                </Upload>
                            </div>
                            <div style={{marginTop: 20,}}>
                                <span className='heading-page'>Charge Point Details</span>
                                <div style={{margin: "0px 0px",}}>
                                    <Form.Item
                                        name="name"
                                        rules={[{required: true, message: 'Please input name!'}]}
                                    >
                                        <Input
                                            placeholder="Name"
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <div style={{
                                        display: "flex",
                                        backgroundColor: "#C4C4C4",
                                        borderRadius: 50,
                                        overflow: "hidden"
                                    }}>
                                        <div onClick={() => {
                                            setIsPublic(true)
                                        }} style={{
                                            width: "50%",
                                            textAlign: "center",
                                            backgroundColor: isPublic ? "#39B64B" : "#c4c4c4",
                                            padding: 10,
                                            borderRadius: 50,
                                            cursor: "pointer"
                                        }}>
                                            <span style={{
                                                textTransform: "uppercase",
                                                fontWeight: "bold",
                                                color: "white"
                                            }}>Public</span>
                                        </div>
                                        <div onClick={() => {
                                            setIsPublic(false)
                                        }} style={{
                                            width: "50%",
                                            textAlign: "center",
                                            backgroundColor: !isPublic ? "#39B64B" : "#c4c4c4",
                                            padding: 10,
                                            borderRadius: 50,
                                            cursor: "pointer"
                                        }}>
                                            <span style={{
                                                textTransform: "uppercase",
                                                fontWeight: "bold",
                                                color: "white"
                                            }}>private</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <div style={{
                                        display: "flex",
                                        backgroundColor: "#C4C4C4",
                                        borderRadius: 50,
                                        overflow: "hidden"
                                    }}>
                                        <div onClick={() => {
                                            setIsOperative(true)
                                        }} style={{
                                            width: "50%",
                                            textAlign: "center",
                                            backgroundColor: isOperative ? "#39B64B" : "#c4c4c4",
                                            padding: 10,
                                            borderRadius: 50,
                                            cursor: "pointer"
                                        }}>
                                            <span style={{
                                                textTransform: "uppercase",
                                                fontWeight: "bold",
                                                color: "white"
                                            }}>Operative</span>
                                        </div>
                                        <div onClick={() => {
                                            setIsOperative(false)
                                        }} style={{
                                            width: "50%",
                                            textAlign: "center",
                                            backgroundColor: !isOperative ? "#39B64B" : "#c4c4c4",
                                            padding: 10,
                                            borderRadius: 50,
                                            cursor: "pointer"
                                        }}>
                                            <span style={{
                                                textTransform: "uppercase",
                                                fontWeight: "bold",
                                                color: "white"
                                            }}>Non Operative</span>
                                        </div>
                                    </div>
                                </div>
                                <span className='heading-page'>Charge point Location</span>
                                <div style={{margin: "0px 0px",}}>
                                    <Form.Item
                                        name="address1"
                                        rules={[{required: true, message: 'Please input address 1!'}]}
                                    >
                                        <Input
                                            placeholder="Address Line 1"
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "0px 0px",}}>
                                    <Form.Item
                                        name="address2"
                                        rules={[{required: true, message: 'Please input address 2!'}]}
                                    >
                                        <Input
                                            placeholder="Address Line 2"
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Row>
                                        <Col md={6}>
                                            <div>
                                                <Form.Item
                                                    name="postalCode"
                                                    rules={[{required: true, message: 'Please input Postal Code!'}]}
                                                >
                                                    <Input
                                                        placeholder="Postal Code"
                                                        className='input-box'
                                                        onChange={(e) => {

                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div>
                                                <Form.Item
                                                    name="city"
                                                    rules={[{required: true, message: 'Please input City!'}]}
                                                >
                                                    <Input
                                                        placeholder="City"
                                                        className='input-box'
                                                        onChange={(e) => {

                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Row>
                                        <Col md={6}>
                                            <div style={{margin: "0px 0px",}}>
                                                <Form.Item
                                                    name="state"
                                                    rules={[{required: true, message: 'Please input State!'}]}
                                                >
                                                    <Input
                                                        placeholder="State"
                                                        className='input-box'
                                                        onChange={(e) => {

                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col md={6}>

                                        </Col>
                                    </Row>
                                </Container>
                                <div>
                                    <Form.Item
                                        name="latitude"
                                        rules={[{required: true, message: 'Please input Latitude!'}]}
                                    >
                                        <Input
                                            placeholder="Latitude"
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "0px 0px",}}>
                                    <Form.Item
                                        name="longitude"
                                        rules={[{required: true, message: 'Please input Longitude!'}]}
                                    >
                                        <Input
                                            placeholder="Longitude"
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <span className='heading-page'>Price</span>
                                <Radio.Group style={{width: '100%'}}
                                             onChange={(e) => setSelectedPriceMode(e.target.value)}
                                             value={selectedPriceMode}>
                                    <Row style={{marginTop: 10}}>
                                        {/*    <Col xs={1}>*/}
                                        {/*<span style={{display: "block", marginTop: 8}}>*/}
                                        {/*    <Form.Item*/}
                                        {/*        name="RATE"*/}
                                        {/*        valuePropName="checked"*/}
                                        {/*    >*/}
                                        {/*        <Radio value={"RATE"}/>*/}
                                        {/*    </Form.Item>*/}
                                        {/*</span>*/}
                                        {/*    </Col>*/}
                                        <Col xs={4}>
                                            <div>
                                                <Form.Item
                                                    name="privilegeRate"
                                                    rules={[{required: true, message: 'Please input Privilege Rate!'}]}
                                                >
                                                    <Input
                                                        placeholder="Privilege Rate"
                                                        className='input-box'
                                                        onChange={(e) => {
                                                            setInitPrivilegeRate(e.target.value)
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(%)</h6>
                                            </span>
                                        </Col>
                                        <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>Privilege member will be charged {parseFloat(initPrivilegeRate) * 100}% of the total price.</h6>
                                            </span>
                                        </Col>
                                        {/*<Col xs={2}>*/}
                                        {/*    <span style={{display: "block", marginTop: 5}}>(RM/min)</span>*/}
                                        {/*    <span style={{display: "block", marginTop: 40}}>(RM/min)</span>*/}
                                        {/*</Col>*/}
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <div>
                                                <Form.Item
                                                    name="rate"
                                                    rules={[{required: selectedPriceMode === "RATE", message: 'Please input Rate!'}]}
                                                >
                                                    <Input
                                                        placeholder="Rate"
                                                        className='input-box'
                                                        onChange={(e) => {
                                                            setInitRate(e.target.value)
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col xs={1}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <Form.Item
                                                    name="RATE"
                                                    valuePropName="checked"
                                                >
                                                    <Radio value={"RATE"}/>
                                                </Form.Item>
                                            </span>
                                        </Col>
                                        <Col xs={3}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(RM/min)</h6>
                                            </span>
                                        </Col>
                                        <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>You are charging at RM {initRate} per minute.</h6>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <div>
                                                <Form.Item
                                                    name="tariff"
                                                    rules={[{required: selectedPriceMode === "TARIFF", message: 'Please input Tariff!'}]}
                                                >
                                                    <Input
                                                        placeholder="Tariff"
                                                        className='input-box'
                                                        onChange={(e) => {
                                                            setInitTariff(e.target.value)
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col xs={1}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <Form.Item
                                                    name="TARIFF"
                                                    valuePropName="checked"
                                                >
                                                    <Radio value={"TARIFF"}/>
                                                </Form.Item>
                                            </span>
                                        </Col>
                                        <Col xs={3}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(RM/kWh)</h6>
                                            </span>
                                        </Col>
                                        <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>You are charging at RM {initTariff} per kWh.</h6>
                                            </span>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                                <span className='heading-page'>Idle Fee</span>
                                <Form.Item>
                                    <Row style={{marginTop: 10}}>
                                        <Col xs={4}>
                                            <div>
                                                <Form.Item
                                                    name="idleFee"
                                                    rules={[{required: true, message: 'Please input idle fee!'}]}
                                                >
                                                    <Input
                                                        placeholder="Idle Fee"
                                                        defaultValue={0}
                                                        className='input-box'
                                                        onChange={(e) => {
                                                            setInitIdleFee(e.target.value)
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(RM)</h6>
                                            </span>
                                        </Col>
                                        <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6></h6>
                                            </span>
                                        </Col>
                                        {/*<Col xs={2}>*/}
                                        {/*    <span style={{display: "block", marginTop: 5}}>(RM/min)</span>*/}
                                        {/*    <span style={{display: "block", marginTop: 40}}>(RM/min)</span>*/}
                                        {/*</Col>*/}
                                    </Row>
                                    <Row style={{marginTop: 10}}>
                                        <Col xs={4}>
                                            <div>
                                                <Form.Item
                                                    name="bufferTime"
                                                    rules={[{required: true, message: 'Please input idle fee buffer time!'}]}
                                                >
                                                    <Input
                                                        placeholder="Idle Fee Buffer Time (min)"
                                                        defaultValue={10}
                                                        className='input-box'
                                                        onChange={(e) => {
                                                            setInitBufferTime(e.target.value)
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(min)</h6>
                                            </span>
                                        </Col>
                                        <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6></h6>
                                            </span>
                                        </Col>
                                        {/*<Col xs={2}>*/}
                                        {/*    <span style={{display: "block", marginTop: 5}}>(RM/min)</span>*/}
                                        {/*    <span style={{display: "block", marginTop: 40}}>(RM/min)</span>*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Form.Item>

                                {
                                    loading ? (
                                        <LoadingSpinner/>
                                    ) : (
                                        <Button style={{marginTop: 20}} block type="primary" htmlType="submit"
                                                className="login-form-button">
                                            Add Charge Point
                                        </Button>
                                    )
                                }
                            </div>
                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    )
}

export default Home