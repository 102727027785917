import React, {useEffect, useState} from 'react';
import {Card, Space, Image, Input, Modal, DatePicker, Table} from 'antd';
import './index.css';
import { CaretRightOutlined, FunnelPlotOutlined, FilterOutlined } from '@ant-design/icons';
import { Form, Button, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Container, Row, UncontrolledPopover, PopoverHeader, PopoverBody, FormGroup, Label } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import axios from "axios";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {AuditLog, AuditLogResp} from "../../../models/dto/auditLog.dto";
import moment from "moment";

interface AuditLogDataSourceProps {
    userId: string | null,
    longitude: number,
    latitude: number,
}

const AuditLogView: React.FC = ({ children }) => {

    const [selectedTimeRange, setSelectedTimeRange] = useState<any>([]);

    const [logDataSource, setLogDataSource] = useState<AuditLogDataSourceProps[]>();
    const { token } = useCredentialStore(({ token }) => ({
        token
    }));
    const { RangePicker } = DatePicker;
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (selectedTimeRange.length === 2) {
            axios.get(`/api/auditLog?page=0&pageSize=1000&fromTime=${moment(selectedTimeRange[0] * 1000).unix()}&toTime=${moment(selectedTimeRange[1] * 1000).unix()}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                const response: AuditLogResp = res.data;
                setLogDataSource(response.data.map(log => {
                    return {
                        userId: log.userId.id,
                        longitude: log.longitude,
                        latitude: log.latitude,
                    }
                }));
            }).finally(() => {
                setIsModalVisible(false);
            })
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getAuditLog = () => {
        axios.get("/api/auditLog?page=0&pageSize=1000", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            const response: AuditLogResp = res.data;
            setLogDataSource(response.data.map(log => {
                return {
                    userId: log.userId.id,
                    longitude: log.longitude,
                    latitude: log.latitude,
                }
            }));
        })
    }

    const columns = [
        {
            title: 'User Id',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
        },
    ];

    useEffect(() => {
        getAuditLog();
    }, []);

    return (
        <div>
            <span className='page-title'>Superadmin | Audit log</span>
            <Container fluid={true} style={{marginTop: 20}}>
                <Row style={{ marginBottom: 10 }}>
                    <Col style={{ textAlign: "right" }}>
                        <span id="PopoverLegacy1" style={{ padding: "5px 15px", cursor: "pointer", display: "inline-block" }}>
                            <FilterOutlined style={{ fontSize: 22 }} onClick={() => setIsModalVisible(true)} />
                        </span>
                    </Col>
                </Row>
                <Row md={12}>
                    <Table dataSource={logDataSource} columns={columns} />
                </Row>
            </Container>
            <Modal
                title="Time Range Filter"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" style={{ backgroundColor: '#39b64b', borderColor: '#39b64b' }} onClick={handleOk}>
                        Search
                    </Button>,
                ]}
            >
                <Row>
                    <Col md={12}>
                        <div style={{ margin: "10px 0px", }}>
                            <RangePicker renderExtraFooter={() => 'extra footer'} showTime onChange={(value) => setSelectedTimeRange(value)} />
                        </div>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default AuditLogView
