import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Sentry.init({
//     dsn: "https://3e258dae35fa49f6af3df9d59fea13c6@o1160764.ingest.sentry.io/6245429",
//     integrations: [new BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
