import create, { SetState } from 'zustand';
import {combine, devtools, NamedSet, persist} from 'zustand/middleware';
import axios from 'axios';
import jwt_decode from 'jwt-decode';


type initialStateType = { token: string; refresh: string; isRunning: boolean; isError: boolean; isManual: boolean; timeoutId: number; }

type jwtDecodedBody = {
    "sub": string,
    "userId": string,
    "role": string,
    "iss": string,
    "iat": number,
    "exp": number
}

const initialState: initialStateType = {
    token: '',
    refresh: '',
    isRunning: true,
    isError: false,
    isManual: false,
    timeoutId: 0,
};

const refreshToken = async (token: string, refresh: string, set: SetState<initialStateType>, manual: boolean) => {
    await axios
        .post(`/api/auth/token`, {
            "refreshToken": refresh
        },{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            const decodedToken: jwtDecodedBody = jwt_decode(res.data.accessToken);
            set((state) => ({
                ...state,
                isError: false,
                token: res.data.accessToken,
                refresh: res.data.refreshToken
            }));
            return;
            // if (manual) {
            //     set((state) => ({
            //         ...state,
            //         isError: false,
            //         token: res.data.accessToken,
            //         refresh: res.data.refreshToken
            //     }));
            //     return;
            // }
            // const timeoutId = setTimeout(() => {
            //     refreshToken(token, refresh, set, manual);
            //     /**
            //      * slightly earlier to refresh token before token expiration,
            //      * prevents old / expired token from being used on the next request
            //      */
            // }, decodedToken.exp - 500);
            // }, 5000);
            // @ts-ignore
            // set((state) => ({
            //     ...state,
            //     isError: false,
            //     token: res.data.token,
            //     timeoutId,
            // }));
        })
        .catch((_) => {
            set((state) => ({
                ...state,
                isError: true,
                // errorMessage: err.response.data.message ?? '',
            }));
        })
        .finally(() => set((state) => ({ ...state, isManual: false, isRunning: false })));
};

export const useCredentialStore = create(
    devtools(
        persist(
            combine({ ...initialState }, (set, get) => ({
                ...initialState,
                credentialUpdate: (token: string, refresh: string) => {
                    set((state) => ({
                        ...state,
                        token,
                        refresh
                    }));
                },
                refreshToken: (manual = false) => {
                    set((state) => ({ ...state, isRunning: true, isManual: manual }));
                    const { token, refresh } = get();
                    if (!token) {
                        set((state) => ({ ...state, isRunning: false }));
                        return;
                    }
                    return refreshToken(token, refresh, set, manual);
                },
                signOut: async () => {
                    // const { timeoutId } = get();
                    // clearInterval(timeoutId);
                    // const res = await axios.get(`/api/v1/session/logout`).catch((_) => {
                    //     console.error(`Sign out failure`);
                    // });
                    // res.status === 200 &&
                    // set((state) => ({
                    //     ...state,
                    //     token: ''
                    // }));
                },
                getGroups: () => {
                    const { token } = get();
                    const { role }: jwtDecodedBody = jwt_decode(token);
                    return role;
                },
            })),
            {
                name: 'credential', // unique name
                getStorage: () => sessionStorage,
            },
        ),
        'credential',
    ),
);