import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Card, Space, Image, Avatar, AutoComplete, Modal} from 'antd';
import './index.css';
import {
    Col,
    Input,
    Container,
    Row,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
    FormGroup,
    Label
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {
    useNavigate
} from 'react-router-dom';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
} from "react-google-maps";
import {InfoBox} from "@react-google-maps/api";
import Search from "antd/es/input/Search";
import axios from "axios";
import {getChargePointBasedOnAdminBody, getChargePointBasedOnAdminResponse} from "../../../types/superAdmin";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {getChargePointBasedOnTextSearchBody, getChargePointBasedOnTextSearchResponse} from "../../../types/admin";
import chargePointDetails from "../../ChargePoint/ChargePointDetails";
import {getBase64BasedOnImageId} from "../../../utils/image";
import {getChargePointSummaryBody, getChargePointSummaryBodyResponse} from "../../../models/dto/chargePointSummary.dto";


const Map: React.FC = ({children}) => {

    const [ActiveTab, setActiveTab] = useState("settings");
    const [dataSource, setDataSource] = useState<string[]>([])
    const [imageSrc, setImageSrc] = useState<any>("")
    const navigate = useNavigate();
    const {token} = useCredentialStore(({token}) => ({
        token
    }));
    const [mainChargePointList, setMainChargePointList] = useState<getChargePointSummaryBody[]>([])
    const [selectedChargePointDetails, setSelectedChargePointDetails] = useState<getChargePointSummaryBody>()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({
        lat: 0,
        lng: 0
    })
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    })

    // googlemap
    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const options = { closeBoxURL: '', enableEventPropagation: true };

    const onLoad = (infoBox: any) => {
        console.log('infoBox: ', infoBox)
    };

    const onSetChargerDetails = (lat: number, lng: number) => {
        mainChargePointList.map((chargePointDetails) => {
            if (parseFloat(chargePointDetails.location.latitude ) === lat && parseFloat(chargePointDetails.location.longitude) === lng) {
                setCenter({
                    lat: lat,
                    lng: lng
                })
                setSelectedChargePointDetails(chargePointDetails)
                if (chargePointDetails.imageId.id !== null) {
                    setImageSrc(`/api/image/${chargePointDetails.imageId.id}`)
                    showModal()
                } else {
                    setImageSrc("")
                    showModal()
                    return
                }
            }
        })
    }

    const MapWithAMarker = withScriptjs(withGoogleMap((props: any) =>
        <GoogleMap
            defaultZoom={8}
            defaultCenter={center}
        >
            {
                mainChargePointList.map((chargePointDetails) => (
                    <Marker
                        icon={{
                            url: require("../../../assets/images/default_icon.png"),
                            scaledSize: new window.google.maps.Size(40, 40)
                        }}
                        position={{lat: parseFloat(chargePointDetails.location.latitude), lng: parseFloat(chargePointDetails.location.longitude)}}
                        title={chargePointDetails.name}
                        onClick={({ latLng }: google.maps.MapMouseEvent) => {
                            if (latLng) {
                                onSetChargerDetails(latLng.lat(), latLng.lng())
                            }
                        }}
                    />
                ))
            }
        </GoogleMap>
    ));

    const onSearch = (value: string) => {
        setDataSource([])
        if (value != null) {
            let orignalNameList: string[] = []
            let listToShow: string[] = []
            for (let i = 0; i < mainChargePointList.length; i++) {
                orignalNameList.push(mainChargePointList[i].name)
            }

            for (let i = 0; i < orignalNameList.length; i++) {
                if (orignalNameList[i].includes(value)) {
                    listToShow.push(orignalNameList[i])
                }
            }
            setDataSource(listToShow)
        }
    };

    useEffect(() => {
        axios
            .get(`/api/chargePointsSummary?page=0&pageSize=1000`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargePointList: getChargePointSummaryBodyResponse = res.data;
                setMainChargePointList(chargePointList.data);

                let nameList: string[] = []
                for (let i = 0; i < chargePointList.data.length; i++) {
                    nameList.push(chargePointList.data[i].name)
                }
                setDataSource(nameList)

            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                // setChargePointLoading(false);
                // setChargerListToSubmit([])
            });
    }, [])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function(position) {
            setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
        });
    }, [])

    const onSelect = (value: string | null) => {
        if  (value != null) {
            // eslint-disable-next-line array-callback-return
            mainChargePointList.map((chargePointDetails) => {
                if (chargePointDetails.name === value) {
                    setSelectedChargePointDetails(chargePointDetails)
                    setCenter({
                        lat: parseFloat(chargePointDetails.location.latitude),
                        lng: parseFloat(chargePointDetails.location.longitude)
                    })
                    return
                }
            })
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <Container fluid={true}>
                <Row style={{marginBottom: 10}}>
                    <Col md={6}>
                        <span className='page-title'>Superadmin | Map</span>
                    </Col>
                    <Col md={6} style={{textAlign: "right"}}>
                        <span onClick={() => {
                            navigate(`/admin/add-charge-point`, {replace: false});
                        }} style={{
                            backgroundColor: "#39B64B",
                            padding: "5px 15px",
                            cursor: "pointer",
                            color: "white",
                            borderRadius: 7,
                            display: "inline-block",
                            textTransform: "uppercase",
                            marginRight: 10
                        }}>
                            + new charge point
                        </span>
                        <span onClick={() => {
                            navigate(`/admin/add-charger`, {replace: false});
                        }} style={{
                            backgroundColor: "#39B64B",
                            padding: "5px 15px",
                            cursor: "pointer",
                            color: "white",
                            borderRadius: 7,
                            display: "inline-block",
                            textTransform: "uppercase",
                            marginRight: 10
                        }}>
                            + new charger
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {/*<Input*/}
                        {/*    placeholder="input search text"*/}
                        {/*    allowClear*/}
                        {/*    onChange={(e) => onSearch(e.target.value)}*/}
                        {/*/>*/}
                        <AutoComplete
                            dataSource={dataSource}
                            style={{ width: 200 }}
                            onSelect={onSelect}
                            onSearch={onSearch}
                            placeholder="input here"
                        />
                    </Col>
                </Row>
                <Row md={12}>
                    <Col md={12} className='card-datavisulization'
                         style={{textAlign: "left", padding: 20, height: "auto"}}>
                        <MapWithAMarker
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC1tqIXXvBbZ9uQ9fjrIhdkkSUGgzpmoLA&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `80vh`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                        />
                    </Col>
                </Row>
            </Container>
            <Modal title="Charge Point Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={700}>
                <Row>
                    <Col span={6} order={4}>
                        <Image
                            width={200}
                            src={imageSrc === "" ? require("../../../assets/images/default_pic.PNG") : imageSrc}
                        />
                    </Col>
                    <Col span={6} order={3}>
                        Charge Point Name:
                        <br />
                        { selectedChargePointDetails?.name }
                        <br />
                        { selectedChargePointDetails?.location.longitude } | { selectedChargePointDetails?.location.latitude }
                        <br />
                        <br />
                        <br />
                        Total Energy (kWh): { selectedChargePointDetails?.summary.totalEnergy }
                        <br />
                        Total Transaction: { selectedChargePointDetails?.summary.totalTransaction }
                        <br />
                        Total Earnings (RM): { selectedChargePointDetails?.summary.totalEarning }
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default Map
