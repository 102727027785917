import React, {useEffect, useState} from 'react';
import {Card, Space, Image, Avatar, Input, Checkbox} from 'antd';
import './index.css';
import {CaretRightOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import {Form, Button, Radio} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {
    Col,
    Container,
    Row,
    InputGroup,
    InputGroupText,
    Table,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {useNavigate} from "react-router-dom";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {
    getAdminsBody,
    createAdminProps,
    getAdminsResponse,
    getChargePointBasedOnAdminResponse, getChargePointBasedOnAdminBody
} from "../../../types/superAdmin";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";

const {Search} = Input;

const Roles: React.FC = ({children}) => {

    const [ActiveTab, setActiveTab] = useState("settings");
    const [isModalVisible, setisModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [chargePointLoading, setChargePointLoading] = useState(false);
    const [mainAdminList, setMainAdminList] = useState<getAdminsBody[]>();
    const [selectedAdminId, setSelectedAdminId] = useState<string>();
    const [selectedAdminName, setSelectedAdminName] = useState<string>();
    const [selectedAdminEmail, setSelectedAdminEmail] = useState<string>();
    const [mainChargePointList, setMainChargePointList] = useState<getChargePointBasedOnAdminBody[]>();
    const [isRemoveAdminModalVisible, setIsRemoveAdminModalVisible] = useState(false);
    const [chargerListToSubmit, setChargerListToSubmit] = useState<{ id: string }[]>();
    const [adminNameToShow, setAdminNameToShow] = useState<string>("");
    const [chargePointNameToShow, setChargePointNameToShow] = useState<string[]>([]);
    const { token } = useCredentialStore(({ token }) => ({
        token
    }));
    const navigate = useNavigate()

    const showModal = () => {
        console.log("Hello");

        setisModalVisible(true);
    };

    const handleOk = () => {
        setisModalVisible(false);
        setSubmitLoading(true);
        axios
            .post(`/api/admin/${selectedAdminId}/chargePoints`, chargerListToSubmit,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                PopNotification("success",  "Assigned Successfully");
            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };

    const handleRemoveAdminOk = () => {
        axios
            .delete(`/api/admin/${selectedAdminId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                PopNotification("success",  "Removed Successfully");
                setIsRemoveAdminModalVisible(false);
            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCancel = () => {
        setisModalVisible(false);
    };

    const onSearch = (value: string) => {
        if (value !== "" || value != null || value !== undefined) {
            setLoading(true);
            setSelectedAdminId(value);
            axios
                .get(`/api/admins?page=0&pageSize=10&textSearch=${value}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    const adminList: getAdminsResponse = res.data;
                    setMainAdminList(adminList.data);
                })
                .catch((error) => {
                    PopNotification("error",  error.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const getAdminList = () => {
        axios
            .get(`/api/admins?page=0&pageSize=${9999}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const adminList: getAdminsResponse = res.data;
                setMainAdminList(adminList.data);
            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onGetChargePointList = (admin_id: string, firstName: string, lastName: string) => {
        setChargePointLoading(true);
        setSelectedAdminId(admin_id)
        setAdminNameToShow(`${firstName} ${lastName}`);
        axios
            .get(`/api/chargePointsLocationAndAdminAccess?page=0&pageSize=50&userId=${admin_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargePointList: getChargePointBasedOnAdminResponse = res.data;
                setMainChargePointList(chargePointList.data);

                let assignedChargerIdList: { id: string }[] = []
                for (let i = 0; i < chargePointList.data.length; i++) {
                    if (chargePointList.data[i].userAccess) {
                        assignedChargerIdList.push(chargePointList.data[i].id)
                        chargePointNameToShow.push(chargePointList.data[i].name)
                    }
                }
                setChargerListToSubmit(assignedChargerIdList)

            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                setChargePointLoading(false);
                // setChargerListToSubmit([])
            });
    }

    const onSearchChargePoint = (value: string) => {
        if (value !== "" || value != null || value !== undefined) {
            setChargePointLoading(true);
            axios
                .get(`/api/chargePointsLocationAndAdminAccess?page=0&pageSize=50&userId=${selectedAdminId}&textSearch=${value}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    const chargePointList: getChargePointBasedOnAdminResponse = res.data;
                    setMainChargePointList(chargePointList.data);
                })
                .catch((error) => {
                    PopNotification("error",  error.response.data.message);
                })
                .finally(() => {
                    setChargePointLoading(false);
                    // setChargerListToSubmit([])
                });
        } else {
            setChargePointLoading(true);
            axios
                .get(`/api/chargePointsLocationAndAdminAccess?page=0&pageSize=50&userId=${selectedAdminId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    const chargePointList: getChargePointBasedOnAdminResponse = res.data;
                    setMainChargePointList(chargePointList.data);
                })
                .catch((error) => {
                    PopNotification("error",  error.response.data.message);
                })
                .finally(() => {
                    setChargePointLoading(false);
                    // setChargerListToSubmit([])
                });
        }
    }

    useEffect(() => {
        getAdminList();
    }, []);

    return (
        <div>
            <span className='page-title'>Superadmin | Roles</span>
            <span style={{fontWeight: "600"}}>RExharge Admin list</span>
            <Container fluid={true} style={{marginTop: 20}}>
                <Row>
                    <Col md={8} style={{paddingLeft: 0}}>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={(e) => {
                                onSearch(e.target.value)
                            }}
                            size='large'
                        />
                    </Col>
                    <Col md={4} style={{paddingRight: 0,}}>
                        {/*<span style={{ display: "flex" }}>*/}
                        {/*    <span style={{ display: "inline-block", padding: "5px 20px", cursor: "pointer" }} onClick={() => navigate("/admin/update-admin")}>*/}
                        {/*        <EditOutlined style={{ fontSize: 22 }} />*/}
                        {/*    </span>*/}
                        {/*</span>*/}
                    </Col>
                </Row>
                <Row md={12} style={{marginTop: 20}}>
                    <Col md={12} className='card-datavisulization-user' style={{textAlign: "left", padding: 20, height: "auto"}}>
                        {
                            loading ? (
                                <LoadingSpinner />
                            ) : (
                                <Table bordered responsive>
                                    <thead>
                                    <tr>
                                        <th>
                                            Firstname
                                        </th>
                                        <th>
                                            Lastname
                                        </th>
                                        <th>
                                            Mobile No.
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        mainAdminList?.map((adminDetails) => (
                                            <tr>
                                                <td>
                                                    {adminDetails.firstName}
                                                </td>
                                                <td>
                                                    {adminDetails.lastName}
                                                </td>
                                                <td>
                                                    {adminDetails.mobileNumber}
                                                </td>
                                                <td>
                                                    {adminDetails.email}
                                                </td>
                                                <td style={{textAlign: "center"}}>
                                                    <Button block type="primary" htmlType="button" className={"edit-button"} onClick={() => navigate(`/admin/update-admin?adminId=${adminDetails.id.id}`)}>
                                                        Edit
                                                    </Button>
                                                    <Button block type="primary" htmlType="button" className={"assign-button"} onClick={() => onGetChargePointList(adminDetails.id.id, adminDetails.firstName, adminDetails.lastName)}>
                                                        Assign Access
                                                    </Button>
                                                    <Button block type="primary" htmlType="button" className={"remove-button"} onClick={() => {
                                                        setSelectedAdminName(adminDetails.firstName + " " + adminDetails.lastName);
                                                        setSelectedAdminEmail(adminDetails.email);
                                                        setSelectedAdminId(adminDetails.id.id);
                                                        setIsRemoveAdminModalVisible(true)
                                                    }}>
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            )
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{paddingRight: 0, paddingLeft: 0,}}>
                        <span style={{
                            backgroundColor: "#39B64B",
                            padding: "5px 15px",
                            cursor: "pointer",
                            color: "white",
                            borderRadius: 7,
                            display: "inline-block",
                            textTransform: "uppercase",
                            marginRight: 10
                        }} onClick={() => navigate("/admin/add-admin")}>
                            add new admin
                        </span>
                        {/*<span style={{ backgroundColor: "#39B64B", padding: "5px 15px", cursor: "pointer", color: "white", borderRadius: 7, display: "inline-block", textTransform: "uppercase", marginRight: 10 }} onClick={() => {*/}
                        {/*    setIsRemoveAdminModalVisible(true)*/}
                        {/*}}>*/}
                        {/*    remove admin*/}
                        {/*</span>*/}
                    </Col>
                </Row>
            </Container>
            <span style={{fontWeight: "600", marginTop: 20, display: "block"}}>Private Charge Point Access (appear after clicking “assign access”)</span>
            <Container fluid={true} style={{marginTop: 10}}>
                <Row>
                    <Col style={{paddingLeft: 0}} md={8}>
                        <InputGroup>
                            <Input
                                placeholder="input search text"
                                allowClear
                                onChange={(e) => {
                                    onSearchChargePoint(e.target.value)
                                }}
                                size='large'
                            />
                        </InputGroup>
                    </Col>
                    <Col md={4}>
                        {
                            submitLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <Button onClick={() => {
                                    setisModalVisible(true)
                                }} block type="primary" className="login-form-button">
                                    Save
                                </Button>
                            )
                        }
                    </Col>
                </Row>
                <Row md={12} style={{marginTop: 20}}>
                    <Col md={12} className='card-datavisulization'
                         style={{textAlign: "left", padding: 20, height: "auto"}}>
                        {
                            chargePointLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <Table bordered responsive>
                                    <thead>
                                    <tr>
                                        <th>
                                            Charge Point
                                        </th>
                                        <th>
                                            Address
                                        </th>
                                        <th>
                                            Access
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        mainChargePointList?.map((chargePointDetails) => (
                                            <tr>
                                                <td>
                                                    {chargePointDetails.name}
                                                </td>
                                                <td>
                                                    {chargePointDetails?.location?.address1} {chargePointDetails?.location?.address2}
                                                </td>
                                                <td style={{textAlign: "center"}}>
                                                    <Checkbox defaultChecked={chargePointDetails.userAccess} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            // @ts-ignore
                                                            setChargerListToSubmit([...chargerListToSubmit, {id: chargePointDetails.id.id}])
                                                            setChargePointNameToShow([...chargePointNameToShow, chargePointDetails.name])
                                                        } else {
                                                            // @ts-ignore
                                                            setChargerListToSubmit(chargerListToSubmit.filter(charger => charger.id !== chargePointDetails.id.id))
                                                            setChargePointNameToShow(chargePointNameToShow.filter(name => name !== chargePointDetails.name))
                                                        }
                                                    }} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            )
                        }
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={isModalVisible} toggle={() => setisModalVisible(!isModalVisible)}>
                <ModalHeader toggle={() => setisModalVisible(!isModalVisible)}>Confirmation Message</ModalHeader>
                <ModalBody>
                    <span style={{display: "block"}}>Assign: </span>
                    <span style={{display: "block"}}>{ chargePointNameToShow.join(", ") }</span>
                    <span style={{display: "block"}}>to:</span>
                    <span style={{display: "block"}}>{adminNameToShow}?</span>
                </ModalBody>
                <ModalFooter>
                    <Button danger type="primary" onClick={handleCancel}>CANCEL</Button>{' '}
                    <Button type="primary" style={{backgroundColor: "#39b64b"}} onClick={handleOk}>YES</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isRemoveAdminModalVisible} toggle={() => setIsRemoveAdminModalVisible(false)}>
                <ModalHeader toggle={() => setIsRemoveAdminModalVisible(false)}>Confirmation Message</ModalHeader>
                <ModalBody>
                    <span style={{display: "block"}}>Remove Admin?: </span>
                    <span style={{display: "block"}}>{ selectedAdminName }</span>
                    <span style={{display: "block"}}>{ selectedAdminEmail }</span>
                    {/*<span style={{display: "block"}}>(email)</span>*/}
                </ModalBody>
                <ModalFooter>
                    <Button danger type="primary" onClick={() => setIsRemoveAdminModalVisible(false)}>CANCEL</Button>{' '}
                    <Button type="primary" style={{backgroundColor: "#39b64b"}}
                            onClick={handleRemoveAdminOk}>YES</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Roles
