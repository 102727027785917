import {Button} from "antd";
import axios from "axios";
import {SHA256} from "../../utils/sha256";
import {useEffect, useRef, useState} from "react";
import {LoadingSpinner} from "../../shared/LoadingSpinner";
import {hostname} from "os";

export const PaymentView = () => {

    const formRef = useRef(null);
    const queryParams = new URLSearchParams(window.location.search);

    const refNo = queryParams.get('refNo') ?? '';
    const totalAmount = queryParams.get('amount') ?? '';
    const username = queryParams.get('username') ?? '';
    const email = queryParams.get('email') ?? '';
    const mobileNo = queryParams.get('mobileNo') ?? '';
    const uniqueId = queryParams.get('uniqueId') ?? '';

    useEffect(() => {
        // @ts-ignore
        axios.post("/api/v2/payment/card/", null, {
            headers: {
                "Authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwMDExQHRlc3R1c2VyLmNvbSIsInVzZXJJZCI6IjE1ZDNiMjAzLTk4YWEtNDBlMy1hMGU2LTA0ZmQ4MTBjMWVhYiIsInJvbGUiOiJBUFBfVVNFUiIsImlzcyI6InBhbnRhc2FpLmNvbSIsImlhdCI6MTcwMDQ4ODI4MSwiZXhwIjoxNzAwNDk3MjgxfQ.tgzk-l7PLosA9y4MD7raFR778WvIGRF2jsBTOA5iy3jd3siXNiOLwJRYae7CQHiaLKCCWKLsHpVDKR3DSzc-QA"
            }
        }).then(response => {

            window.location.href = response.data.redirectUrl;
        }).catch(error => {
            if (error.response && [301, 302].includes(error.response.status)) {
                        const redirectUrl = error.response.headers.location;
                        window.location.href = redirectUrl;
                        return;
                    }
                    return Promise.reject(error);
        });
    }, [])

    return (
        <>
            <form ref={formRef} method="post" name="ePayment" action="http://localhost:8080/api/v2/payment/card/">
                <input type={"hidden"} name={"MerchantCode"} value={"M39920"} />
                <input type={"hidden"} name={"PaymentId"} value={""} />
                <input type={"hidden"} name={"RefNo"} value={refNo} />
                <input type={"hidden"} name={"Amount"} value={Number(totalAmount).toFixed(2)} />
                <input type={"hidden"} name={"Currency"} value={"MYR"} />
                <input type={"hidden"} name={"ProdDesc"} value={"Rexharge Charging"} />
                <input type={"hidden"} name={"UserName"} value={username} />
                <input type={"hidden"} name={"UserEmail"} value={email} />
                <input type={"hidden"} name={"UserContact"} value={mobileNo} />
                <input type={"hidden"} name={"Remark"} value={uniqueId} />
                <input type={"hidden"} name={"Lang"} value={"UTF-8"} />
                <input type={"hidden"} name={"SignatureType"} value={"SHA256"} />
                <input type={"hidden"} name={"Signature"} value={SHA256('By4iS2pTe0M39920'+refNo+`${(Number(totalAmount).toFixed(2)).replaceAll(".","")}MYR`)} />
                <input type={"hidden"} name={"ResponseURL"} value={"https://web.rexharge.net/api/payment/result"} />
                <input type={"hidden"} name={"BackendURL"} value={"https://web.rexharge.net/api/payment/done"} />
            </form>
            <h1>Redirecting to payment, please don't close this page.</h1>
        </>
    )
}
