import React, {useEffect, useState} from 'react';
import {
    BrowserRouter,
    Route,
    Routes, Navigate
} from "react-router-dom";
import Login from "./pages/Login";
// import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChargePoints from "./pages/ChargePoint/ChargePoints";
import UserLayout from "./layouts/UserLayout";
import EventLog from "./pages/ChargePoint/EventLog";
import ChargePointSetting from "./pages/ChargePoint/Setting";
import Report from "./pages/ChargePoint/Report";
import Inquires from "./pages/Inquires";
import UserAccess from "./pages/UserManagement/Access";
import UserTransaction from "./pages/UserManagement/Transaction";
import Map from "./pages/SuperAdmin/Map";
import Roles from "./pages/SuperAdmin/Roles";
import AddCharger from "./pages/SuperAdmin/AddCharger";
import AddChargePoint from "./pages/SuperAdmin/AddChargePoint";
import SuperAdminReport from "./pages/SuperAdmin/Report";
import ChargePointDetails from "./pages/ChargePoint/ChargePointDetails";
import ChargerDetails from "./pages/ChargePoint/ChargerDetails";
import AddAdmin from "./pages/SuperAdmin/AddAdmin";
import UpdateAdmin from "./pages/SuperAdmin/UpdateAdmin";
import {MobileHandling} from "./pages/MobileHandling";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import "./theme/main.less"

import {useCredentialStore} from "./hooks/useCredentialsStore";
import SingleChargePoint from "./pages/ChargePoint/SingleChargePoint";
import AuditLogView from "./pages/SuperAdmin/AuditLog";
import {PaymentView} from "./pages/Payment";
import {PaymentSuccessView} from "./pages/Payment/Success";
import {PaymentFailedView} from "./pages/Payment/Failed";
import {PaymentErrorView} from "./pages/Payment/Error";
import EventLogView from "./pages/ChargePoint/EventLog";
import {CarBarrierPage} from "./pages/CarBarrier";
import {ForgetPasswordOtpPage} from "./pages/ForgetPassword/ForgetPasswordOtp";
import {ResetPasswordPage} from "./pages/ForgetPassword/ResetPassword";
import {NotificationPage} from "./pages/SuperAdmin/NotificationPage";
import {ECoinTrackingPage} from "./pages/SuperAdmin/ECoinTracking";
import {CardPage} from "./pages/SuperAdmin/Card";

function App() {

    const { refreshToken, signOut, isManual, token } = useCredentialStore(
        ({ refreshToken, signOut, isManual, token }) => ({
            refreshToken,
            signOut,
            isManual,
            token,
        }),
    );

    useEffect(() => {
        setInterval(() => {
            refreshToken();
        }, 720000)
    }, []);

    return (
        <>
            <BrowserRouter>
                {
                    <Routes>
                        <Route
                            path="/"
                            element={<Navigate to="/login" />}
                        />
                        <Route path="/mobile" element={<MobileHandling/>}/>
                        <Route path="/" element={<UserLayout/>}>
                            <Route path="/charge-point" element={<ChargePoints/>}/>
                            <Route path="/event-log" element={<EventLogView/>}/>
                            <Route path="/charge-point-settings" element={<ChargePointSetting/>}/>
                            <Route path="/report" element={<Report/>}/>
                            <Route path="/inquires" element={<Inquires/>}/>
                            <Route path="/access" element={<UserAccess/>}/>
                            <Route path="/transaction" element={<UserTransaction/>}/>
                            <Route path="/charge-point/details" element={<ChargePointDetails />}/>
                            <Route path="/charger/details" element={<SingleChargePoint />}/>
                            <Route path="/charger" element={<ChargerDetails />}/>
                            <Route path="/admin/car-barrier" element={<CarBarrierPage />}/>
                            <Route path="/admin/audit-log" element={<AuditLogView/>}/>
                            <Route path="/admin/map" element={<Map/>}/>
                            <Route path="/admin/super-admin-report" element={<SuperAdminReport />}/>
                            <Route path="/admin/roles" element={<Roles/>}/>
                            <Route path="/admin/add-admin" element={<AddAdmin />}/>
                            <Route path="/admin/update-admin" element={<UpdateAdmin />}/>
                            <Route path="/admin/add-charger" element={<AddCharger />}/>
                            <Route path="/admin/add-charge-point" element={<AddChargePoint />}/>
                            <Route path="/admin/notification" element={<NotificationPage />}/>
                            <Route path="/admin/e-coin-tracking" element={<ECoinTrackingPage />}/>
                            <Route path="/admin/card" element={<CardPage/>}/>
                        </Route>
                        <Route element={<Login/>} path="/login"/>
                        <Route element={<ForgetPasswordOtpPage />} path="/forget-password"/>
                        <Route element={<ResetPasswordPage />} path="/reset-password"/>
                        <Route path="/payment" element={<PaymentView />}/>
                        <Route path="/payment/success" element={<PaymentSuccessView />}/>
                        <Route path="/payment/failed" element={<PaymentFailedView />}/>
                        <Route path="/payment/error" element={<PaymentErrorView />}/>
                        {/*<Route*/}
                        {/*    path="*"*/}
                        {/*    element={*/}
                        {/*        <main style={{padding: "1rem"}}>*/}
                        {/*            <p>There's nothing here!</p>*/}
                        {/*        </main>*/}
                        {/*    }*/}
                        {/*/>*/}
                    </Routes>
                }
            </BrowserRouter>
        </>
    );
}

export default App;
