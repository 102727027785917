import React, {useEffect, useState} from 'react';
import { Card, Input, Space, Table, Pagination } from 'antd';
import './index.css';
import { CaretRightOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
    useNavigate
} from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {EventLog, EventResp} from "../../../models/dto/eventLog.dto";
import moment from "moment";

const { Search } = Input;

const EventLogView: React.FC = ({ children }) => {

    const onSearch = (value: string) => console.log(value);
    const [loading, setLoading] = useState(false);
    const [eventLogList, setEventLogList] = useState<EventLog[]>([]);
    const queryParams = new URLSearchParams(window.location.search)
    const charge_point_id = queryParams.get("chargePointId")
    const charge_point_name = queryParams.get("chargePointName")
    const city_name = queryParams.get("cityName")

    const {getGroups, token} = useCredentialStore(({getGroups, token}) => ({
        getGroups,
        token
    }));

    const columns = [
        {
            title: 'Created Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (record: any) => {
                return (
                    <>
                        <p>{ moment(record).format('YYYY-MM-DD HH:mm:ss') }</p>
                    </>
                )
            },
        },
        {
            title: 'Vendor Error Code',
            dataIndex: 'vendorErrorCode',
            key: 'vendorErrorCode',
        },
        {
            title: 'Event Type',
            dataIndex: 'eventType',
            key: 'eventType',
        },
    ];

    const getEventLog = () => {
        axios
            .get(`/api/chargePoint/${charge_point_id}/eventLog?page=0&pageSize=100`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const response: EventResp = res.data;
                setEventLogList(response.data)
            })
            .catch((error) => {
                console.log(error)
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getEventLog()
    }, []);


    return (
        <div>
            <span className='page-title'>{charge_point_name} | {city_name}</span>
            <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Space style={{ width: '100%' }} direction={"vertical"}>
                    <Row>
                        <Col md={12}>
                            <Search
                                placeholder="input search text"
                                allowClear
                                onSearch={onSearch}
                                size='large'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Table dataSource={eventLogList} columns={columns} />
                        </Col>
                    </Row>
                </Space>
            </Container>
        </div>
    )
}

export default EventLogView
