import {Button, Col, Form, Image, Input, Row, Space} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";
import React, {useState} from "react";
import useWindowsDimensionHook from "../../../hooks/useWindowsDimensionHook";
import {useNavigate} from "react-router-dom";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";

export const ResetPasswordPage = () => {

    const [newPassword, setNewPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const onResetPassword = () => {

        if (newPassword === "" || newPassword == null) {
            PopNotification("error", "Please enter new password");
            return;
        }

        setLoading(true);

        axios
            .post("/api/admin/otpResetPassword", {
                token: localStorage.getItem("forgetPasswordToken"),
                password: newPassword
            })
            .then(() => {
                PopNotification("success", "Password reset successfully");
                navigate("/login")
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message ?? "Invalid Token");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <div className='login-background'>
                <Row>
                    <Col span={8} offset={8} className='login-form-container'>
                        <div className='text-center'>
                            <Image
                                src={require("./../../../assets/images/brand-name-logo.png")}
                                preview={false}
                            />
                        </div>
                        <span className='login-header'>Reset Password</span>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onResetPassword}
                        >
                            <Form.Item
                                name="newPassword"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input
                                    type={"password"}
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    placeholder="Password"
                                    className='input-box'
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                    }}
                                    autoFocus
                                />
                            </Form.Item>
                            <Form.Item>
                                {
                                    loading ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <Space direction={"vertical"} style={{ width: '100%' }}>
                                            <Button block type="primary" htmlType="submit" className="login-form-button">
                                                Reset
                                            </Button>
                                        </Space>
                                    )
                                }
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    )
}
