import React, {useEffect, useState} from 'react';
import { Card, Space, Image, Avatar } from 'antd';
import './index.css';
import { CaretRightOutlined, FunnelPlotOutlined, AntDesignOutlined } from '@ant-design/icons';
import { Form, Button, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Input, Container, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {useCredentialStore} from "../../hooks/useCredentialsStore";
import axios from "axios";
import {PopNotification} from "../../shared/notification";
import {aboutUsResponse, faqBody, faqResponse} from "../../types/admin";


const Inquires: React.FC = ({ children }) => {

    const [faqs, setFaqs] = useState<faqBody[]>([]);
    const [aboutUs, setAboutUs] = useState<string>('');

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const getFaqList = () => {
        axios
            .get("api/faqs/web?page=0&pageSize=100", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {

                const faqBodyResponse: faqResponse = res.data;
                setFaqs(faqBodyResponse.data)

            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {

            });
    }

    const getAboutUs = () => {
        axios
            .get("/api/aboutUs", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {

                const aboutUsBodyResponse: aboutUsResponse = res.data;
                setAboutUs(aboutUsBodyResponse.text)

            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {

            });
    }

    useEffect(() => {
        getFaqList();
        getAboutUs()
    }, [])

    return (
        <div>
            <span className='page-title'>Inquiries</span>
            <span style={{ fontWeight: "600", marginBottom: 10, display: "block" }}>FAQ</span>
            <Container fluid={true}>
                <Row>
                    <Col md={12}>
                        <div className='card-datavisulization' style={{ textAlign: "left", padding: 10, height: "auto" }}>
                            <ol>
                                {
                                    faqs.map((faq) => (
                                        <li style={{ fontWeight: "600", marginBottom: 10 }}>
                                            { faq.question }
                                            <span style={{ display: "block", marginTop: 5, fontSize: 14, fontWeight: "normal" }}>{ faq.answer }</span>
                                        </li>
                                    ))
                                }
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
            <span style={{ fontWeight: "600", display: "block", marginBottom: 10, }}>About Us</span>
            <Container fluid={true}>
                <Row>
                    <Col md={12}>
                        <div className='card-datavisulization' style={{ textAlign: "left", padding: 20, height: "auto" }}>
                            <p>{ aboutUs }</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Inquires