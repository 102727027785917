import {Button, Form, Input} from 'antd';
import React from 'react';
import {baseAxiosInstance} from "../../../axios/axiosBase";
import axios from "axios";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";

export const NotificationPage = () => {

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const onFinish = (values: any) => {
        axios.post("/api/postNotification", {}, {
            params: {
                subject: values.subject,
                content: values.content,
                topic: "public"
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <span className='page-title'>Notification</span>

            <Form
                name="notification"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Notification Subject"
                    name="subject"
                    rules={[{required: true, message: 'Please input your notification subject!'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Notification Content"
                    name="content"
                    rules={[{required: true, message: 'Please input your notification content!'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
