import React, {useEffect, useState} from 'react';
import {Card, Space, Image, Form, Upload} from 'antd';
import './index.css';
import {CaretRightOutlined, FunnelPlotOutlined, AntDesignOutlined} from '@ant-design/icons';
import {Button, Radio} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Col, Input, Container, Row} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {useParams} from "react-router";
import {
    addChargePointProps,
    getChargePointDetailsProps,
    updateChargePointDetailsProps
} from "../../../types/superAdmin";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {getBase64} from "../../../utils/image";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";
import {ChargePointReport} from "../ChargePointReport";


const ChargePointSetting: React.FC = ({children}) => {

    const [ActiveTab, setActiveTab] = useState("settings");
    const [Type, setType] = useState("Public");
    const [OperativeType, setOperativeType] = useState("Operative");
    const [initChargePointDetails, setInitChargePointDetails] = useState<getChargePointDetailsProps>()
    const {getGroups, token} = useCredentialStore(({getGroups, token}) => ({
        getGroups,
        token
    }));
    const [form] = Form.useForm();
    const [profileImageId, setProfileImageId] = useState<string>("");
    const [isPublic, setIsPublic] = useState<boolean>(true);
    const [isOperative, setIsOperative] = useState<boolean>(true);

    const [initRate, setInitRate] = useState<any>();
    const [initTariff, setInitTariff] = useState<any>();
    const [initPrivilegeRate, setInitPrivilegeRate] = useState<any>();

    const queryParams = new URLSearchParams(window.location.search)
    const charge_point_id = queryParams.get("chargePointId")

    const [selectedPriceMode, setSelectedPriceMode] = useState<string>("RATE");
    const [loading, setLoading] = useState(false);

    const [initIdleFee, setInitIdleFee] = useState<any>();
    const [initBufferTime, setInitBufferTime] = useState<any>();

    const getChargePointDetails = () => {
        axios
            .get(`/api/chargePoint/${charge_point_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log(res.data);
                setInitChargePointDetails(res.data);

                setIsOperative(res.data.isOperative);
                setIsPublic(res.data.isPublic);

                setProfileImageId(res.data.imageId.id);

                // Set Form Value
                setSelectedPriceMode(res.data.method === "RATE" ? "RATE" : "TARIFF");

                setInitRate(res.data.rate);
                setInitTariff(res.data.tariff);
                setInitPrivilegeRate(res.data.privilegeRate);
                setInitIdleFee(res.data.idleFee);
                setInitBufferTime(res.data.bufferTime);

                form.setFieldsValue({
                    name: res.data.name,
                    address1: res.data.location.address1,
                    address2: res.data.location.address2,
                    postalCode: res.data.location.postalCode,
                    city: res.data.location.city,
                    state: res.data.location.state,
                    longitude: res.data.location.longitude,
                    latitude: res.data.location.latitude,
                    privilegeRate: res.data.privilegeRate,
                    rate: res.data.rate,
                    tariff: res.data.tariff,
                    idleFee: res.data.idleFee,
                    bufferTime: res.data.bufferTime
                });

                // Get Image
                // if (res.data.imageId.id != null) {
                //     axios
                //         .get("/api/image/" + res.data.imageId.id, {
                //             headers: {
                //                 Authorization: `Bearer ${token}`
                //             }
                //         })
                //         .then((res) => {
                //             setProfileImageBase64(res.data)
                //         })
                //         .catch((error) => {
                //             PopNotification("error", error.response.data.message);
                //         })
                //         .finally(() => {
                //             // setLoading(false);
                //         });
                // }


            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const onEditChargePoint = () => {
        form.validateFields().then((values) => {
            setLoading(true);
            const dataToSubmit: updateChargePointDetailsProps = {
                createdTime: initChargePointDetails!.createdTime,
                id: {
                    id: initChargePointDetails!.id.id,
                },
                name: values.name,
                isPublic: isPublic,
                imageId: {
                    id: profileImageId
                },
                isOperative: isOperative,
                rate: (values.rate === null || values.rate === "" || values.rate === undefined) ? 0 : parseFloat(values.rate),
                tariff: (values.tariff === null  || values.tariff === "" || values.tariff === undefined) ? 0 : parseFloat(values.tariff),
                method: selectedPriceMode,
                privilegeRate: parseFloat(values.privilegeRate),
                location: {
                    createdTime: initChargePointDetails!.location.createdTime,
                    id: {
                        id: initChargePointDetails!.location.id.id,
                    },
                    address1: values.address1,
                    address2: values.address2,
                    postalCode: values.postalCode,
                    city: values.city,
                    state: values.state,
                    longitude: values.longitude,
                    latitude: values.latitude,
                    chargePointId: {
                        id: initChargePointDetails!.id.id,
                    }
                },
                idleFee: parseFloat(initIdleFee?? '0'),
                bufferTime: parseFloat(initBufferTime?? '0'),
            }

            axios
                .post("/api/chargePoint", dataToSubmit, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    PopNotification("success", "Charge Point Updated Successfully");
                })
                .catch((error) => {
                    PopNotification("error", error.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }

    useEffect(() => {
        getChargePointDetails()
    }, [])

    return (
        <div>
            <span className='page-title'>Charge Point</span>
            <div style={{ display: "flex", backgroundColor: "#C4C4C4", borderRadius: 50, overflow: "hidden" }}>
                <div onClick={() => {
                    setActiveTab("settings");
                }} style={{ width: "50%", textAlign: "center", backgroundColor: ActiveTab === "settings" ? "#39B64B" : "#c4c4c4", padding: 10, borderRadius: 50, cursor: "pointer" }}>
                    <span style={{ textTransform: "uppercase", fontWeight: "bold", color: "white" }}>Settings</span>
                </div>
                {/*<div onClick={() => {*/}
                {/*    setActiveTab("reports");*/}
                {/*}} style={{*/}
                {/*    width: "50%",*/}
                {/*    textAlign: "center",*/}
                {/*    backgroundColor: ActiveTab === "reports" ? "#39B64B" : "#c4c4c4",*/}
                {/*    padding: 10,*/}
                {/*    borderRadius: 50,*/}
                {/*    cursor: "pointer"*/}
                {/*}}>*/}
                {/*    <span style={{textTransform: "uppercase", fontWeight: "bold", color: "white"}}>reports</span>*/}
                {/*</div>*/}
            </div>

            <>
                {/*<span className='page-title'>Update Charge Point</span>*/}
                <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onEditChargePoint}
                    >
                        <Row>
                            <Col md={6}>
                                <div style={{marginTop: 20,}}>
                                    {/*<span className='heading-page'>Charge Point Profile Image</span>*/}
                                    <Upload
                                        accept="image/png, image/jpeg"
                                        maxCount={1}
                                        // listType="picture-card"
                                        showUploadList={false}
                                        customRequest={(file: any) => {
                                            const isLt2M = file.file.size / 1024 / 1024 / 1024 < 500;
                                            if (isLt2M) {
                                                getBase64(file.file).then((base64String: any) => {
                                                    const dataToSubmit = {
                                                        base64: base64String,
                                                    }

                                                    axios
                                                        .post("/api/image", dataToSubmit, {
                                                            headers: {
                                                                Authorization: `Bearer ${token}`
                                                            }
                                                        })
                                                        .then((res) => {
                                                            setProfileImageId(res.data.id.id)
                                                            PopNotification("success", "Profile Image Uploaded");
                                                        })
                                                        .catch((error) => {
                                                            PopNotification("error", error.response.data.message);
                                                        })
                                                });

                                            } else {
                                                PopNotification("error", "Image size should not be greater than 500kb size");
                                            }

                                        }} // to override the component sending request on image upload, see https://stackoverflow.com/a/51519603/4858751
                                        beforeUpload={() => console.log("before upload")} // see https://ant.design/components/upload/#components-upload-demo-avatar
                                        onChange={() => console.log("handle change")} // see https://ant.design/components/upload/#components-upload-demo-avatar
                                    >
                                        {profileImageId ? (
                                            <Image
                                                preview={false}
                                                width={200}
                                                src={`/api/image/` + profileImageId}
                                            />
                                        ) : <Button block type="primary" className="login-form-button">Upload Profile
                                            Image</Button>}
                                    </Upload>
                                </div>
                                <div style={{marginTop: 20,}}>
                                    <span className='heading-page'>Charge Point Details</span>
                                    <div style={{margin: "0px 0px",}}>
                                        <Form.Item
                                            name="name"
                                            rules={[{required: true, message: 'Please input name!'}]}
                                        >
                                            <Input
                                                placeholder="Name"
                                                className='input-box'
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{margin: "10px 0px",}}>
                                        <div style={{
                                            display: "flex",
                                            backgroundColor: "#C4C4C4",
                                            borderRadius: 50,
                                            overflow: "hidden"
                                        }}>
                                            <div onClick={() => {
                                                setIsPublic(true)
                                            }} style={{
                                                width: "50%",
                                                textAlign: "center",
                                                backgroundColor: isPublic ? "#39B64B" : "#c4c4c4",
                                                padding: 10,
                                                borderRadius: 50,
                                                cursor: "pointer"
                                            }}>
                                            <span style={{
                                                textTransform: "uppercase",
                                                fontWeight: "bold",
                                                color: "white"
                                            }}>Public</span>
                                            </div>
                                            <div onClick={() => {
                                                setIsPublic(false)
                                            }} style={{
                                                width: "50%",
                                                textAlign: "center",
                                                backgroundColor: !isPublic ? "#39B64B" : "#c4c4c4",
                                                padding: 10,
                                                borderRadius: 50,
                                                cursor: "pointer"
                                            }}>
                                            <span style={{
                                                textTransform: "uppercase",
                                                fontWeight: "bold",
                                                color: "white"
                                            }}>private</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{margin: "10px 0px",}}>
                                        <div style={{
                                            display: "flex",
                                            backgroundColor: "#C4C4C4",
                                            borderRadius: 50,
                                            overflow: "hidden"
                                        }}>
                                            <div onClick={() => {
                                                setIsOperative(true)
                                            }} style={{
                                                width: "50%",
                                                textAlign: "center",
                                                backgroundColor: isOperative ? "#39B64B" : "#c4c4c4",
                                                padding: 10,
                                                borderRadius: 50,
                                                cursor: "pointer"
                                            }}>
                                            <span style={{
                                                textTransform: "uppercase",
                                                fontWeight: "bold",
                                                color: "white"
                                            }}>Operative</span>
                                            </div>
                                            <div onClick={() => {
                                                setIsOperative(false)
                                            }} style={{
                                                width: "50%",
                                                textAlign: "center",
                                                backgroundColor: !isOperative ? "#39B64B" : "#c4c4c4",
                                                padding: 10,
                                                borderRadius: 50,
                                                cursor: "pointer"
                                            }}>
                                            <span style={{
                                                textTransform: "uppercase",
                                                fontWeight: "bold",
                                                color: "white"
                                            }}>Non Operative</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span className='heading-page'>Charge point Location</span>
                                    <div style={{margin: "0px 0px",}}>
                                        <Form.Item
                                            name="address1"
                                            rules={[{required: true, message: 'Please input address 1!'}]}
                                        >
                                            <Input
                                                placeholder="Address Line 1"
                                                className='input-box'
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{margin: "0px 0px",}}>
                                        <Form.Item
                                            name="address2"
                                            rules={[{required: true, message: 'Please input address 2!'}]}
                                        >
                                            <Input
                                                placeholder="Address Line 2"
                                                className='input-box'
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                                        <Row>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Item
                                                        name="postalCode"
                                                        rules={[{required: true, message: 'Please input Postal Code!'}]}
                                                    >
                                                        <Input
                                                            placeholder="Postal Code"
                                                            className='input-box'
                                                            onChange={(e) => {

                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Item
                                                        name="city"
                                                        rules={[{required: true, message: 'Please input City!'}]}
                                                    >
                                                        <Input
                                                            placeholder="City"
                                                            className='input-box'
                                                            onChange={(e) => {

                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                                        <Row>
                                            <Col md={6}>
                                                <div style={{margin: "0px 0px",}}>
                                                    <Form.Item
                                                        name="state"
                                                        rules={[{required: true, message: 'Please input State!'}]}
                                                    >
                                                        <Input
                                                            placeholder="State"
                                                            className='input-box'
                                                            onChange={(e) => {

                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col md={6}>

                                            </Col>
                                        </Row>
                                    </Container>
                                    <div>
                                        <Form.Item
                                            name="latitude"
                                            rules={[{required: true, message: 'Please input Latitude!'}]}
                                        >
                                            <Input
                                                placeholder="Latitude"
                                                className='input-box'
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{margin: "0px 0px",}}>
                                        <Form.Item
                                            name="longitude"
                                            rules={[{required: true, message: 'Please input Longitude!'}]}
                                        >
                                            <Input
                                                placeholder="Longitude"
                                                className='input-box'
                                                onChange={(e) => {

                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <span className='heading-page'>Price</span>
                                    <Radio.Group style={{width: '100%'}}
                                                 onChange={(e) => setSelectedPriceMode(e.target.value)}
                                                 value={selectedPriceMode}>
                                        <Row style={{marginTop: 10}}>
                                            {/*    <Col xs={1}>*/}
                                            {/*<span style={{display: "block", marginTop: 8}}>*/}
                                            {/*    <Form.Item*/}
                                            {/*        name="RATE"*/}
                                            {/*        valuePropName="checked"*/}
                                            {/*    >*/}
                                            {/*        <Radio value={"RATE"}/>*/}
                                            {/*    </Form.Item>*/}
                                            {/*</span>*/}
                                            {/*    </Col>*/}
                                            <Col xs={4}>
                                                <div>
                                                    <Form.Item
                                                        name="privilegeRate"
                                                        rules={[{required: true, message: 'Please input Privilege Rate!'}]}
                                                    >
                                                        <Input
                                                            placeholder="Privilege Rate"
                                                            className='input-box'
                                                            onChange={(e) => {

                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(%)</h6>
                                            </span>
                                            </Col>
                                            <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>Privilege member will be charged {parseFloat(initPrivilegeRate) * 100}% of the total price.</h6>
                                            </span>
                                            </Col>
                                            {/*<Col xs={2}>*/}
                                            {/*    <span style={{display: "block", marginTop: 5}}>(RM/min)</span>*/}
                                            {/*    <span style={{display: "block", marginTop: 40}}>(RM/min)</span>*/}
                                            {/*</Col>*/}
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <div>
                                                    <Form.Item
                                                        name="rate"
                                                        rules={[{required: selectedPriceMode === "RATE", message: 'Please input Rate!'}]}
                                                    >
                                                        <Input
                                                            placeholder="Rate"
                                                            className='input-box'
                                                            onChange={(e) => {

                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col xs={1}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <Form.Item
                                                    name="RATE"
                                                    valuePropName="checked"
                                                >
                                                    <Radio value={"RATE"}/>
                                                </Form.Item>
                                            </span>
                                            </Col>
                                            <Col xs={3}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(RM/min)</h6>
                                            </span>
                                            </Col>
                                            <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>You are charging at RM {initRate} per minute.</h6>
                                            </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <div>
                                                    <Form.Item
                                                        name="tariff"
                                                        rules={[{required: selectedPriceMode === "TARIFF", message: 'Please input Tariff!'}]}
                                                    >
                                                        <Input
                                                            placeholder="Tariff"
                                                            className='input-box'
                                                            onChange={(e) => {

                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col xs={1}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <Form.Item
                                                    name="TARIFF"
                                                    valuePropName="checked"
                                                >
                                                    <Radio value={"TARIFF"}/>
                                                </Form.Item>
                                            </span>
                                            </Col>
                                            <Col xs={3}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(RM/kWh)</h6>
                                            </span>
                                            </Col>
                                            <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>You are charging at RM {initTariff} per kWh.</h6>
                                            </span>
                                            </Col>
                                        </Row>
                                    </Radio.Group>
                                    <span className='heading-page'>Idle Fee</span>
                                    <Form.Item>
                                        <Row style={{marginTop: 10}}>
                                            <Col xs={4}>
                                                <div>
                                                    <Form.Item
                                                        name="idleFee"
                                                        rules={[{required: true, message: 'Please input idle fee!'}]}
                                                    >
                                                        <Input
                                                            placeholder="Idle Fee"
                                                            defaultValue={0}
                                                            className='input-box'
                                                            onChange={(e) => {
                                                                setInitIdleFee(e.target.value)
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(RM)</h6>
                                            </span>
                                            </Col>
                                            <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6></h6>
                                            </span>
                                            </Col>
                                            {/*<Col xs={2}>*/}
                                            {/*    <span style={{display: "block", marginTop: 5}}>(RM/min)</span>*/}
                                            {/*    <span style={{display: "block", marginTop: 40}}>(RM/min)</span>*/}
                                            {/*</Col>*/}
                                        </Row>
                                        <Row style={{marginTop: 10}}>
                                            <Col xs={4}>
                                                <div>
                                                    <Form.Item
                                                        name="bufferTime"
                                                        rules={[{required: true, message: 'Please input idle fee buffer time!'}]}
                                                    >
                                                        <Input
                                                            placeholder="Idle Fee Buffer Time (min)"
                                                            defaultValue={10}
                                                            className='input-box'
                                                            onChange={(e) => {
                                                                setInitBufferTime(e.target.value)
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6>(min)</h6>
                                            </span>
                                            </Col>
                                            <Col xs={4}>
                                            <span style={{display: "block", marginTop: 8}}>
                                                <h6></h6>
                                            </span>
                                            </Col>
                                            {/*<Col xs={2}>*/}
                                            {/*    <span style={{display: "block", marginTop: 5}}>(RM/min)</span>*/}
                                            {/*    <span style={{display: "block", marginTop: 40}}>(RM/min)</span>*/}
                                            {/*</Col>*/}
                                        </Row>
                                    </Form.Item>

                                    {
                                        loading ? (
                                            <LoadingSpinner/>
                                        ) : (
                                            <Button style={{marginTop: 20}} block type="primary" htmlType="submit"
                                                    className="login-form-button">
                                                Update Charge Point
                                            </Button>
                                        )
                                    }
                                </div>
                            </Col>
                            <Col md={6}>

                            </Col>
                        </Row>
                    </Form>
                </Container>
            </>
        </div>
    )
}

export default ChargePointSetting
