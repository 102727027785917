import React, {useEffect, useState} from 'react';
import {Card, Col, Input, Row, Space, Image, Pagination, Modal} from 'antd';
import './index.css';
import {
    CaretRightOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    StepBackwardOutlined
} from '@ant-design/icons';
import {
    useNavigate
} from "react-router-dom";
import {useParams} from "react-router";
import {
    chargePointBody,
    chargePointListType,
    chargerBody,
    chargerListType,
    chargerListWithImage
} from "../../../types/admin";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";

const ChargePointDetails: React.FC = ({children}) => {

    const {getGroups, token} = useCredentialStore(({getGroups, token}) => ({
        getGroups,
        token
    }));

    const queryParams = new URLSearchParams(window.location.search)
    const charge_point_id = queryParams.get("chargePointId")
    const city_name = queryParams.get("cityName")

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [chargerDetails, setChargerDetails] = useState<chargerListType>();
    const [textSearch, setTextSearch] = useState('');
    const [selectedChargerToDelete, setSelectedChargerToDelete] = useState<{ id: string, unique_id: string, city: string | undefined | null }>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const onSearch = (value: string) => setTextSearch(value);
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {

        axios
            .delete(`/api/charger/${selectedChargerToDelete?.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                PopNotification("success", "Charge Point Deleted Successfully");
                getChargerList();

            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setIsModalVisible(false);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getChargerList = () => {
        axios
            .get(textSearch === '' ? `/api/chargePoint/${charge_point_id}/chargerSocketStatusSummary?pageSize=${pageSize}&page=${page}` : `/api/chargePoint/${charge_point_id}/chargerSocketStatusSummary?pageSize=${pageSize}&page=${page}&textSearch=${textSearch}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(async (res) => {
                setChargerDetails(res.data)
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    useEffect(() => {
        getChargerList();
    }, [page, pageSize, textSearch]);

    return (
        <div>
            <span className='page-title'>Charge Point Details</span>
            <Row>
                <Col md={24}>
                    <Input
                        placeholder="input search text"
                        allowClear
                        onChange={(e) => onSearch(e.target.value)}
                        size='large'
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{marginTop: 20,}}>
                {
                    chargerDetails?.data.map((chargerDetail, index) => (
                        <Col md={12}>
                            <Card
                                size="small"
                                style={{backgroundColor: "#999797", borderRadius: 12, cursor: "pointer"}}>
                                <h6
                                    style={{color: 'white'}}
                                    onClick={() => {
                                        navigate(`/charger/details?cityName=${city_name}&uniqueId=${chargerDetail.uniqueId}&chargerId=${chargerDetail.id.id}`, {replace: false});
                                    }}>{chargerDetail.uniqueId} | {city_name}</h6>
                                {
                                    getGroups() === "SYS_ADMIN" && (
                                        <div style={{position: "absolute", right: 40, top: 10}}>
                                            <DeleteOutlined
                                                style={{fontSize: '150%', color: 'white'}}
                                                onClick={() => {
                                                    setSelectedChargerToDelete({
                                                        id: chargerDetail.id.id,
                                                        unique_id: chargerDetail.uniqueId,
                                                        city: city_name
                                                    })
                                                    showModal()
                                                }}/>
                                        </div>
                                    )
                                }
                                <div style={{position: "absolute", right: 10, top: 10}}>
                                    <SettingOutlined style={{fontSize: '150%', color: 'white'}} onClick={() => {
                                        navigate(`/charger?chargerId=${chargerDetail.id.id}&chargePointId=${charge_point_id}`, {replace: false});
                                    }}/>
                                </div>
                                <hr style={{border: '1px solid white', color: 'white'}}/>
                                <div onClick={() => {
                                    navigate(`/charger/details?cityName=${city_name}&uniqueId=${chargerDetail.uniqueId}&chargerId=${chargerDetail.id.id}`, {replace: false});
                                }}>
                                    <Row gutter={[16, 16]}>
                                        <Col md={10}>
                                            <Image
                                                src={
                                                    chargerDetail.imageId.id === null ?
                                                        require("../../../assets/images/default_pic.PNG")
                                                        : `/api/image/` + chargerDetail.imageId.id
                                                }
                                                preview={false}
                                            />
                                        </Col>
                                        <Col md={14}>
                                <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#C4C4C4"
                                    }}>{chargerDetail.socketStatusSummary.total}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Charging Socket</span>
                                </span>
                                            <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#47B02D"
                                    }}>{chargerDetail.socketStatusSummary.unoccupied}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Unoccupied</span>
                                </span>
                                            <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#DE4545"
                                    }}>{chargerDetail.socketStatusSummary.occupied}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Occupied</span>
                                </span>
                                            <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#E98F26"
                                    }}>{chargerDetail.socketStatusSummary.reserved}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Reserved</span>
                                </span>
                                            <span style={{display: "block", marginBottom: 10}}>
                                    <span style={{
                                        display: "inline-block",
                                        paddingLeft: 5,
                                        paddingRight: 7,
                                        backgroundColor: "#D2F019"
                                    }}>{chargerDetail.socketStatusSummary.underMaintenance}</span>
                                    <span style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        color: "white",
                                        fontWeight: "500"
                                    }}>Under maintenance</span>
                                </span>
                                        </Col>
                                    </Row>
                                </div>

                            </Card>
                        </Col>
                    ))
                }
            </Row>
            <span style={{display: "block", textAlign: "right", marginTop: 20,}}>
                <span style={{display: "block"}}>
                    <Pagination defaultCurrent={1} total={page}/>
                </span>
            </span>

            <Modal
                title="Delete Chargee"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Confirm"
                cancelText="Cancel"
            >
                <p>Are you sure want to delete chargee? </p>
                <p>Unique Id: {selectedChargerToDelete?.unique_id}</p>
                <p>City: {selectedChargerToDelete?.city} </p>
            </Modal>
        </div>
    )
}

export default ChargePointDetails
