import {Button, Col, Form, Image, Input, Row, Space} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";
import React, {useState} from "react";
import useWindowsDimensionHook from "../../../hooks/useWindowsDimensionHook";
import {useNavigate} from "react-router-dom";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";

export const ForgetPasswordOtpPage = () => {

    const [sentOtp, setSentOtp] = useState(false);
    const [email, setEmail] = useState("");
    const [otpText, setOtpText] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const { credentialUpdate } = useCredentialStore(({ credentialUpdate }) => ({
        credentialUpdate
    }));

    const onVerifyToken = () => {
        setLoading(true);

        axios
            .post("/api/admin/otpVerification", {
                token: localStorage.getItem("forgetPasswordToken"),
                code: otpText
            })
            .then((res: { data: { verified: boolean; }; }) => {
                if (res.data.verified) {
                    navigate("/reset-password");
                } else {
                    PopNotification("error", "Invalid OTP");
                }
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message ?? "Invalid Token");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onSendOtp = () => {

        if (email === "" || email == null) {
            PopNotification("error", "Please enter email");
            return;
        }

        setLoading(true);
        axios
            .post("/api/admin/forgetPassword", {
                email: email
            })
            .then((res: { data: { token: string; }; }) => {
                localStorage.setItem("forgetPasswordToken", res.data.token);
                PopNotification("success", "OTP sent successfully");
                setSentOtp(true);
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message ?? "Invalid Email");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <div className='login-background'>
                <Row>
                    <Col span={8} offset={8} className='login-form-container'>
                        <div className='text-center'>
                            <Image
                                src={require("./../../../assets/images/brand-name-logo.png")}
                                preview={false}
                            />
                        </div>
                        <span className='login-header'>OTP Verification</span>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            {
                                !sentOtp ? (
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: 'Please input your Email!' }]}
                                    >
                                        <Input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="Email"
                                            className='input-box'
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            autoFocus
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        name="code"
                                        rules={[{ required: true, message: 'Please input your Code!' }]}
                                    >
                                        <Input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="OTP Code"
                                            className='input-box'
                                            onChange={(e) => {
                                                setOtpText(e.target.value);
                                            }}
                                            autoFocus
                                        />
                                    </Form.Item>
                                )
                            }
                            <Form.Item>
                                {
                                    loading ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <Space direction={"vertical"} style={{ width: '100%' }}>
                                            {
                                                sentOtp ? (
                                                    <Button block type="primary" htmlType="button" onClick={() => onVerifyToken()} className="login-form-button">
                                                        Verify
                                                    </Button>
                                                ) : (
                                                    <Button block type="primary" htmlType="button" onClick={() => onSendOtp()} className="login-form-button">
                                                        Send OTP
                                                    </Button>
                                                )
                                            }
                                        </Space>
                                    )
                                }
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    )
}
