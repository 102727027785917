import useWindowsDimensionHook from "../../hooks/useWindowsDimensionHook";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const MobileHandling = () => {
    const { width, height, isMobile } = useWindowsDimensionHook();
    const navigate = useNavigate()

    useEffect(() => {
        if (!isMobile) {
            navigate("/login")
        }
    }, [])

    return (
        <div>
            <h1>Mobile Handling</h1>
            <p>
                Mobile handling is the process of handling mobile devices. Mobile
                handling is a process of handling mobile devices. Mobile handling is a
                process of handling mobile devices. Mobile handling is a process of
                handling mobile devices. Mobile handling is a process of handling
                mobile devices. Mobile handling is a process of handling mobile
                devices. Mobile handling is a process of handling mobile devices.
            </p>
        </div>
    );
}