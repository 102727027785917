import React, { useState } from 'react';
import {Card, Space, Image, Input, DatePicker, Modal} from 'antd';
import './index.css';
import { CaretRightOutlined, FunnelPlotOutlined, FilterOutlined } from '@ant-design/icons';
import { Form, Button, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Container, Row, UncontrolledPopover, PopoverHeader, PopoverBody, FormGroup, Label } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";


const SuperAdminReport: React.FC = ({ children }) => {

    const [ActiveTab, setActiveTab] = useState("settings");
    const { RangePicker } = DatePicker;
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <span className='page-title'>Superadmin | Report</span>
            <Container fluid={true} style={{paddingLeft: 0,paddingRight: 0,marginTop: 20}}>
                <Row>
                    <Col md={11}>
                        <div style={{ margin: "10px 0px", }}>
                            <Input
                                placeholder='Search'
                            />
                        </div>
                    </Col>
                    <Col style={{ textAlign: "right", paddingTop: 10, }} md={1}>
                        <span id="PopoverLegacy" style={{ padding: "5px 15px", cursor: "pointer", display: "inline-block" }}>
                            <FilterOutlined style={{ fontSize: 22 }} onClick={() => setIsModalVisible(true)} />
                        </span>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} style={{paddingLeft: 0,paddingRight: 0}}>
                <Row>
                    <Col md={12}>
                        <div className='card-datavisulization' style={{height: "50vh"}}>

                        </div>
                    </Col>
                    <Col md={12}>
                        <div className='card-datavisulization' style={{height: "25vh",paddingTop: "12vh",}}>
                            <span>Data Visualization</span>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className='card-datavisulization' style={{height: "25vh",paddingTop: "12vh"}}>
                            <span>Data Visualization</span>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal
                title="Time Range Filter"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" style={{ backgroundColor: '#39b64b', borderColor: '#39b64b' }} onClick={handleOk}>
                        Search
                    </Button>,
                ]}
            >
                <Row>
                    <Col md={12}>
                        <div style={{ margin: "10px 0px", }}>
                            <RangePicker renderExtraFooter={() => 'extra footer'} showTime />
                        </div>
                    </Col>
                    <Col md={12}>
                        <div style={{ margin: "10px 0px", }}>
                            <Input
                                placeholder='Transaction time more than'
                            />
                        </div>
                    </Col>
                    <Col md={12}>
                        <div style={{ margin: "10px 0px", }}>
                            <Input
                                placeholder='Energy charged more than'
                            />
                        </div>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default SuperAdminReport