import React, {useState} from 'react';
import {Card, Space, Image, Input} from 'antd';
import './index.css';
import {CaretRightOutlined, FunnelPlotOutlined, AntDesignOutlined, UserOutlined, LockOutlined} from '@ant-design/icons';
import {Form, Button, Radio} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Col, Container, Row, ModalHeader, ModalBody, ModalFooter, Modal} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {useNavigate} from "react-router-dom";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {createAdminProps} from "../../../types/superAdmin";


const AddAdmin: React.FC = ({children}) => {

    const [adminFirstName, setAdminFirstName] = useState('');
    const [adminLastName, setAdminLastName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [form] = Form.useForm();
    const [isAddAdminModalVisible, setIsAddAdminModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const onCreateAdmin = () => {
        setLoading(true);
        setIsAddAdminModalVisible(false)
        form.validateFields().then(values => {

            const dataToSubmit: createAdminProps = {
                companyName: values.companyName,
                credential: {
                    password: values.password,
                },
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                mobileNumber: values.mobileNumber,
            }

            axios
                .post("/api/admin", dataToSubmit, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    PopNotification("success", "Admin Created Successfully");
                })
                .catch((error) => {
                    PopNotification("error", error.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const handleAddAdminOk = () => {
        setIsAddAdminModalVisible(false);
    };

    const validatePassword = (rule: any, value: string, callback: (arg0: string | undefined) => void) => {
        if (password !== confirmPassword) {
            callback("Password not Match!");
        } else {
            callback(undefined)
        }
    };

    return (
        <div>
            <span className='page-title'>Add Admin</span>
            <span style={{fontSize: 18}}>General</span>
            <Container fluid={true}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={() => {
                        setIsAddAdminModalVisible(true)
                    }}
                >
                    <Row>
                        <Col md={6} style={{paddingLeft: 0, paddingRight: 0,}}>
                            <div style={{marginTop: 20,}}>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="firstName"
                                        rules={[{required: true, message: 'Please input first name!'}]}
                                    >
                                        <Input
                                            placeholder="First Name"
                                            className='input-box'
                                            onChange={(e) => {
                                                setAdminFirstName(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="lastName"
                                        rules={[{required: true, message: 'Please input last name!'}]}
                                    >
                                        <Input
                                            placeholder="Last Name"
                                            className='input-box'
                                            onChange={(e) => {
                                                setAdminLastName(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="companyName"
                                        rules={[{required: true, message: 'Please input company name!'}]}
                                    >
                                        <Input
                                            placeholder="Company Name"
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="mobileNumber"
                                        rules={[{required: true, message: 'Please input mobile no!'}]}
                                    >
                                        <Input
                                            placeholder="Mobile no."
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="email"
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please input email!'
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Please input valid email!'
                                                }
                                            ]
                                        }
                                    >
                                        <Input
                                            placeholder="Email"
                                            className='input-box'
                                            onChange={(e) => {
                                                setAdminEmail(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="password"
                                        rules={[{required: true, message: 'Please input password!'}]}
                                    >
                                        <Input.Password
                                            placeholder="Password"
                                            className='input-box'
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="confirmPassword"
                                        rules={[{required: true, message: 'Please confirm password!'}, { validator: validatePassword }]}
                                    >
                                        <Input.Password
                                            placeholder="Confirm password"
                                            className='input-box'
                                            onChange={(e) => {
                                                setConfirmPassword(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                {
                                    loading ? (
                                        <LoadingSpinner/>
                                    ) : (
                                        <Button block type="primary" htmlType="submit" className="login-form-button">
                                            Add
                                        </Button>
                                    )
                                }
                            </div>
                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                </Form>
            </Container>

            <Modal isOpen={isAddAdminModalVisible} toggle={handleAddAdminOk}>
                <ModalHeader toggle={handleAddAdminOk}>Confirmation Message</ModalHeader>
                <ModalBody>
                    <span style={{display: "block"}}>Add New Admin?: </span>
                    <span style={{display: "block"}}>{adminFirstName} {adminLastName}</span>
                    <span style={{display: "block"}}>{adminEmail}</span>
                </ModalBody>
                <ModalFooter>
                    <Button danger type="primary" onClick={handleAddAdminOk}>CANCEL</Button>{' '}
                    <Button type="primary" style={{backgroundColor: "#39b64b"}} onClick={onCreateAdmin}>YES</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default AddAdmin