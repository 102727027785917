import React, {useEffect, useState} from 'react';
import {Card, Space, Image, Avatar, Input, Checkbox, Select} from 'antd';
import './index.css';
import { CaretRightOutlined, FunnelPlotOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Button, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
    Col,
    Container,
    Row,
    InputGroup,
    InputGroupText,
    Table,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody, ModalFooter
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {
    getAdminsBody,
    getAdminsResponse,
    getChargePointBasedOnAdminBody,
    getChargePointBasedOnAdminResponse
} from "../../../types/superAdmin";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";
import {
    getChargePointBasedOnUserBody,
    getChargePointBasedOnUserResponse,
    getUsersBody,
    getUsersResponse
} from "../../../types/admin";

const { Search } = Input;

const UserAccess: React.FC = ({ children }) => {

    const [dropDownValue, setDropDownValue] = React.useState("all");

    const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">("asc");

    const [ActiveTab, setActiveTab] = useState("settings");
    const [isModalVisible, setisModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [chargePointLoading, setChargePointLoading] = useState(false);
    const [mainUserList, setMainUserList] = useState<getUsersBody[]>();
    const [selectedUserId, setSelectedUserId] = useState<string>();
    const [mainChargePointList, setMainChargePointList] = useState<getChargePointBasedOnUserBody[]>();
    const [chargerListToSubmit, setChargerListToSubmit] = useState<{ id: string }[]>();
    const { token } = useCredentialStore(({ token }) => ({
        token
    }));
    const [userNameToShow, setUserNameToShow] = useState<string>("");
    const [chargePointNameToShow, setChargePointNameToShow] = useState<string[]>([]);
    const navigate = useNavigate()

    const showModal = () => {
        setisModalVisible(true);
    };

    const handleOk = () => {
        setisModalVisible(false);
        setSubmitLoading(true);
        axios
            .post(`/api/user/${selectedUserId}/chargePoints`, chargerListToSubmit,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                PopNotification("success",  "Assigned Successfully");
            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };

    const handleCancel = () => {
        setisModalVisible(false);
    };

    const onSearch = (value: string) => {
        if (value !== "" || value != null || value !== undefined) {
            setLoading(true);
            setSelectedUserId(value);
            axios
                .get(`/api/users?page=0&pageSize=${9999}&textSearch=${value}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    const userList: getUsersResponse = res.data;
                    setMainUserList(userList.data);
                })
                .catch((error) => {
                    PopNotification("error",  error.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const getUserList = () => {
        setLoading(true);
        axios
            .get(`/api/users?page=0&pageSize=${9999}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const userList: getUsersResponse = res.data;
                setMainUserList(userList.data);
            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onGetChargePointList = (user_id: string, first_name: string, last_name: string) => {
        setChargePointLoading(true);
        setSelectedUserId(user_id)
        setUserNameToShow(`${first_name} ${last_name}`);
        axios
            .get(`/api/private/chargePointsLocationAndUserAccess?page=0&pageSize=${9999}&userId=${user_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargePointList: getChargePointBasedOnUserResponse = res.data;
                setMainChargePointList(chargePointList.data);

                let assignedChargerIdList: { id: string }[] = []
                for (let i = 0; i < chargePointList.data.length; i++) {
                    console.log(chargePointList.data[i])
                    if (chargePointList.data[i].userAccess) {
                        assignedChargerIdList.push(chargePointList.data[i].id)
                    }
                }
                setChargerListToSubmit(assignedChargerIdList)
            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                setChargePointLoading(false);
                // setChargerListToSubmit([])
            });
    }

    const onSearchChargePoint = (value: string | null) => {
        setChargePointLoading(true);
        axios
            .get((value !== "" || value != null || value !== undefined) ? `/api/private/chargePointsLocationAndUserAccess?page=0&pageSize=${50}&userId=${selectedUserId}&textSearch=${value}`
                : `/api/private/chargePointsLocationAndUserAccess?page=0&pageSize=${9999}&userId=${selectedUserId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargePointList: getChargePointBasedOnUserResponse = res.data;
                setMainChargePointList(chargePointList.data);
            })
            .catch((error) => {
                PopNotification("error",  error.response.data.message);
            })
            .finally(() => {
                setChargePointLoading(false);
                // setChargerListToSubmit([])
            });
    }

    const onClickSort = () => {
        axios.get(`/api/users?page=0&pageSize=9999&sortProperty=${dropDownValue}&sortOrder=${sortDirection}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                const userList: getUsersResponse = res.data;
                setMainUserList(userList.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getUserList()
    }, [])

    return (
        <div>
            <span className='page-title'>User Management | Access</span>
            <span style={{fontWeight: "600"}}>RExharge User list</span>
            <Container fluid={true} style={{marginTop: 20}}>

                <Space direction={"vertical"} style={{width: '100%'}}>

                    <Input
                        placeholder="input search text"
                        allowClear
                        onChange={(e) => {
                            onSearch(e.target.value)
                        }}
                        size='large'
                    />
                    <Space direction={"horizontal"}>
                        <Select
                            style={{width: 120}}
                            onChange={(value) => setDropDownValue(value)}
                            placeholder={"Sort Field"}
                            options={[
                                {
                                    value: 'firstName',
                                    label: 'First Name',
                                },
                                {
                                    value: 'lastName',
                                    label: 'Last Name',
                                },
                                {
                                    value: 'email',
                                    label: 'Email',
                                },
                                {
                                    value: 'mobileNumber',
                                    label: 'Mobile Number',
                                },
                            ]}
                        />

                        <Select
                            style={{width: 120}}
                            onChange={(value) => setSortDirection(value)}
                            placeholder={"Sort Order"}
                            options={[
                                {
                                    value: 'asc',
                                    label: 'Ascending',
                                },
                                {
                                    value: 'desc',
                                    label: 'Descending',
                                },
                            ]}
                        />

                        <Button onClick={() => onClickSort()}>Sort</Button>
                    </Space>
                </Space>

                <Row md={12} style={{marginTop: 20}}>
                    <Col md={12} className='card-datavisulization'
                         style={{textAlign: "left", padding: 20, height: "auto"}}>
                        {
                            loading ? (
                                <LoadingSpinner />
                            ) : (
                                <Table bordered responsive>
                                    <thead>
                                    <tr>
                                        <th>
                                            No
                                        </th>
                                        <th>
                                            Firstname
                                        </th>
                                        <th>
                                            Lastname
                                        </th>
                                        <th>
                                            Mobile No.
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        mainUserList?.map((userDetails, index) => (
                                            <tr>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {userDetails.firstName}
                                                </td>
                                                <td>
                                                    {userDetails.lastName}
                                                </td>
                                                <td>
                                                    {userDetails.mobileNumber}
                                                </td>
                                                <td>
                                                    {userDetails.email}
                                                </td>
                                                <td style={{textAlign: "center"}}>
                                                    {/*<Button block type="primary" htmlType="button" className={"edit-button"} onClick={() => navigate(`/admin/update-admin/${adminDetails.id.id}`)}>*/}
                                                    {/*<Button block type="primary" htmlType="button" className={"edit-button"} >*/}
                                                    {/*    Edit*/}
                                                    {/*</Button>*/}
                                                    <Button block type="primary" htmlType="button" className={"full-assign-button"} onClick={() => onGetChargePointList(userDetails.id.id, userDetails.firstName, userDetails.lastName)}>
                                                        Assign Access
                                                    </Button>
                                                    {/*<Button block type="primary" htmlType="button" className={"remove-button"}>*/}
                                                    {/*    Remove*/}
                                                    {/*</Button>*/}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            )
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{paddingRight: 0, paddingLeft: 0,}}>
                        {/*/!*<span style={{ backgroundColor: "#39B64B", padding: "5px 15px", cursor: "pointer", color: "white", borderRadius: 7, display: "inline-block", textTransform: "uppercase", marginRight: 10 }}>*!/*/}
                        {/*/!*    assign access*!/*/}
                        {/*/!*</span>*!/*/}
                        {/*<span style={{*/}
                        {/*    backgroundColor: "#39B64B",*/}
                        {/*    padding: "5px 15px",*/}
                        {/*    cursor: "pointer",*/}
                        {/*    color: "white",*/}
                        {/*    borderRadius: 7,*/}
                        {/*    display: "inline-block",*/}
                        {/*    textTransform: "uppercase",*/}
                        {/*    marginRight: 10*/}
                        {/*}} onClick={() => navigate("/admin/add-admin")}>*/}
                        {/*    add new admin*/}
                        {/*</span>*/}
                        {/*/!*<span style={{ backgroundColor: "#39B64B", padding: "5px 15px", cursor: "pointer", color: "white", borderRadius: 7, display: "inline-block", textTransform: "uppercase", marginRight: 10 }} onClick={() => {*!/*/}
                        {/*/!*    setIsRemoveAdminModalVisible(true)*!/*/}
                        {/*/!*}}>*!/*/}
                        {/*/!*    remove admin*!/*/}
                        {/*/!*</span>*!/*/}
                    </Col>
                </Row>
            </Container>
            <span style={{fontWeight: "600", marginTop: 20, display: "block"}}>Private Charge Point Access (appear after clicking “assign access”)</span>
            <Container fluid={true} style={{marginTop: 10}}>
                <Row>
                    <Col style={{paddingLeft: 0}} md={8}>
                        <InputGroup>
                            <Input
                                placeholder="input search text"
                                allowClear
                                onChange={(e) => {
                                    onSearchChargePoint(e.target.value)
                                }}
                                size='large'
                            />
                        </InputGroup>
                    </Col>
                    <Col md={4}>
                        {
                            submitLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <Button onClick={() => {
                                    setisModalVisible(true)
                                }} block type="primary" className="login-form-button">
                                    Save
                                </Button>
                            )
                        }
                    </Col>
                </Row>
                <Row md={12} style={{marginTop: 20}}>
                    <Col md={12} className='card-datavisulization'
                         style={{textAlign: "left", padding: 20, height: "auto"}}>
                        {
                            chargePointLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <Table bordered responsive>
                                    <thead>
                                    <tr>
                                        <th>
                                            Charge Point
                                        </th>
                                        <th>
                                            Address
                                        </th>
                                        <th>
                                            Access
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        mainChargePointList?.map((chargePointDetails) => (
                                            <tr>
                                                <td>
                                                    {chargePointDetails.name}
                                                </td>
                                                <td>
                                                    {chargePointDetails.location.address1} {chargePointDetails.location.address2}
                                                </td>
                                                <td style={{textAlign: "center"}}>
                                                    <Checkbox defaultChecked={chargePointDetails.userAccess} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            // @ts-ignore
                                                            setChargerListToSubmit([...chargerListToSubmit, {id: chargePointDetails.id.id}])
                                                            setChargePointNameToShow([...chargePointNameToShow, chargePointDetails.name])
                                                        } else {
                                                            // @ts-ignore
                                                            setChargerListToSubmit(chargerListToSubmit.filter(charger => charger.id !== chargePointDetails.id.id))
                                                            setChargePointNameToShow(chargePointNameToShow.filter(name => name !== chargePointDetails.name))
                                                        }
                                                    }} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            )
                        }
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={isModalVisible} toggle={handleCancel}>
                <ModalHeader toggle={handleCancel}>Confirmation Message</ModalHeader>
                <ModalBody>
                    <span style={{display: "block"}}>Assign: </span>
                    <span style={{display: "block"}}>{ chargePointNameToShow.join(", ") }</span>
                    <span style={{display: "block"}}>to:</span>
                    <span style={{display: "block"}}>{ userNameToShow }?</span>
                </ModalBody>
                <ModalFooter>
                    <Button danger type="primary" onClick={handleCancel}>CANCEL</Button>{' '}
                    <Button type="primary" style={{backgroundColor: "#39b64b"}} onClick={handleOk}>YES</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default UserAccess
