import React, {useState} from 'react';
import "./index.css"
import {Layout, Menu, Image} from 'antd';
import {
    AppstoreOutlined,
    BarChartOutlined,
    CloudOutlined,
    ShopOutlined,
    TeamOutlined,
    UserOutlined,
    LogoutOutlined,
    VideoCameraOutlined, DashboardOutlined, CustomerServiceOutlined, SettingOutlined,
} from '@ant-design/icons';
import {
    useNavigate, Outlet
} from 'react-router-dom';
import {useCredentialStore} from "../../hooks/useCredentialsStore";
import {PopNotification} from "../../shared/notification";

const {Header, Content, Footer, Sider} = Layout;

const {SubMenu} = Menu;

const UserLayout: React.FC = () => {

    const navigate = useNavigate();
    const [ActiveOpenKey, setActiveOpenKey] = useState("sub1");
    const [IsCollapsed, setIsCollapsed] = useState(false)
    const {signOut, getGroups} = useCredentialStore(({signOut, getGroups}) => ({
        signOut, getGroups
    }));

    return (
        <Layout>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
                collapsible
                breakpoint="xxl"
                collapsedWidth={70}
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    if (collapsed) {
                        setIsCollapsed(true);
                    } else {
                        setIsCollapsed(false);
                    }
                    console.log(collapsed, type);
                }}
            >
                {
                    IsCollapsed ? <div className='text-center'>
                        <Image
                            src={require("../../assets/images/re.png")}
                            preview={false}
                        />
                    </div> : <div className='text-center'>
                        <Image
                            src={require("../../assets/images/brand-name-logo.png")}
                            preview={false}
                        />
                    </div>
                }

                {IsCollapsed ? null : <span className='menu-text-header'>Main Menu</span>}
                <Menu theme="dark" mode="inline">
                    <SubMenu icon={<DashboardOutlined />} key="1" title="Charge Point">
                        <Menu.Item onClick={() => {
                            setActiveOpenKey("1");
                            navigate(`/charge-point`, {replace: false});
                        }} key="1">Charger</Menu.Item>
                        <Menu.Item onClick={() => {
                            setActiveOpenKey("2");
                            navigate(`/transaction`, {replace: false});
                        }} key="2">Transaction</Menu.Item>
                        <Menu.Item onClick={() => {
                            setActiveOpenKey("3");
                            navigate(`/report`, {replace: false});
                        }} key="3">Report</Menu.Item>
                    </SubMenu>
                    <Menu.Item icon={<CustomerServiceOutlined />} onClick={() => {
                        navigate(`/inquires`, {replace: false});
                    }} key="4">
                        Inquiries
                    </Menu.Item>
                    {
                        getGroups() === "SYS_ADMIN" && (
                            <SubMenu icon={<SettingOutlined />} key="95" title="Super Admin">
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("5");
                                    navigate(`/access`, {replace: false});
                                }} key="5">Access</Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("6");
                                    navigate(`/admin/car-barrier`, {replace: false});
                                }} key="6">Car Barrier</Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("7");
                                    navigate(`/admin/audit-log`, {replace: false});
                                }} key="7">Audit Log</Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("8");
                                    navigate(`/admin/map`, {replace: false});
                                }} key="8">Map</Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("9");
                                    navigate(`/admin/roles`, {replace: false});
                                }} key="9">Roles</Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("10");
                                    navigate(`/report`, {replace: false});
                                }} key="10">Report</Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("11");
                                    navigate(`/admin/notification`, {replace: false});
                                }} key="11">Notification</Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("12");
                                    navigate(`/admin/e-coin-tracking`, {replace: false});
                                }} key="12">E-Coin-Tracking</Menu.Item>
                                <Menu.Item onClick={() => {
                                    setActiveOpenKey("13");
                                    navigate(`/admin/card`, {replace: false});
                                }} key="13">Card</Menu.Item>
                            </SubMenu>
                        )
                    }
                    <Menu.Item icon={<LogoutOutlined />} onClick={() => {
                        signOut().then(() => {
                            PopNotification("success", "Logout Successfully");
                            navigate(`/login`);
                        });
                    }} key="13">
                        Log Out
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout" style={{ marginLeft: IsCollapsed ? 70 : 200 }}>
                <Content style={{ margin: '40px 20px', overflow: 'initial', minHeight: "100vh" }}>
                    <Outlet />
                </Content>
                <Footer style={{ textAlign: 'center' }}>RExharge</Footer>
            </Layout>
        </Layout>
    )
}

export default UserLayout
