import axios from "axios";
import {PopNotification} from "../shared/notification";

export const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const getBase64BasedOnImageId = (imageId: string, token: string) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/api/image/${imageId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                resolve(res.data.base64)
            })
            .catch((error) => {
                reject("")
                // PopNotification("error", error.response.data.message);
            })
    });
}