import React, {useEffect, useState} from 'react';
import { Input } from 'antd';
import './index.css';
import { CaretRightOutlined, FunnelPlotOutlined, AntDesignOutlined } from '@ant-design/icons';
import { Form, Button, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import {useLocation, useParams} from "react-router";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {createAdminProps, getAdminDetailsResponse, updateAdminProps} from "../../../types/superAdmin";
import axios from "axios";
import {PopNotification} from "../../../shared/notification";


const UpdateAdmin: React.FC = ({ children }) => {

    const [adminFirstName, setAdminFirstName] = useState('');
    const [adminLastName, setAdminLastName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [initAdminDetails, setInitAdminDetails] = useState<getAdminDetailsResponse>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const queryParams = new URLSearchParams(window.location.search)
    const admin_id = queryParams.get("adminId")


    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const onUpdateAdmin = () => {
        setLoading(true);
        form.validateFields().then(values => {
            const dataToSubmit: getAdminDetailsResponse = {
                companyName: values.companyName,
                createdTime: initAdminDetails!.createdTime,
                credential: {
                    createdTime: initAdminDetails!.credential.createdTime,
                    id: {
                        id: initAdminDetails!.credential.id.id,
                    },
                    password: values.password,
                    userId: {
                        id: initAdminDetails!.credential.userId.id
                    }
                },
                email: values.email,
                firstName: values.firstName,
                id: {
                    id: initAdminDetails!.id.id
                },
                lastName: values.lastName,
                mobileNumber: values.mobileNumber,
                role: initAdminDetails!.role,
            }

            // const dataToSubmit: getAdminDetailsResponse = {
            //     id: {
            //         id: admin_id
            //     },
            //     companyName: values.companyName,
            //     credential: {
            //         createdTime: values.credentialsCreatedAt,
            //         password: values.password,
            //         userId: {
            //             id: values.credentialsUserId
            //         },
            //         id: {
            //             id: admin_id
            //         },
            //     },
            //     email: values.email,
            //     firstName: values.firstName,
            //     lastName: values.lastName,
            //     mobileNumber: values.mobileNumber,
            //     role: values.role,
            // }

            axios
                .post("/api/admin", dataToSubmit, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    PopNotification("success", "Admin Updated Successfully");
                })
                .catch((error) => {
                    PopNotification("error", error.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const validatePassword = (rule: any, value: string, callback: (arg0: string | undefined) => void) => {
        if (password !== confirmPassword) {
            callback("Password not Match!");
        } else {
            callback(undefined)
        }
    };

    useEffect(() => {
        axios
            .get(`/api/admin/${admin_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const adminDetailsResponse: getAdminDetailsResponse = res.data;
                setInitAdminDetails(adminDetailsResponse);
                form.setFieldsValue({
                    firstName: adminDetailsResponse.firstName,
                    lastName: adminDetailsResponse.lastName,
                    email: adminDetailsResponse.email,
                    companyName: adminDetailsResponse.companyName,
                    mobileNumber: adminDetailsResponse.mobileNumber,
                    role: adminDetailsResponse.role
                });

            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });

    }, []);

    return (
        <div>
            <span className='page-title'>Update Admin</span>
            <span style={{fontSize: 18}}>General</span>
            <Container fluid={true}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onUpdateAdmin}
                >
                    <Row>
                        <Col md={6} style={{paddingLeft: 0, paddingRight: 0,}}>
                            <div style={{marginTop: 20,}}>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="firstName"
                                        rules={[{required: true, message: 'Please input first name!'}]}
                                    >
                                        <Input
                                            placeholder="First Name"
                                            className='input-box'
                                            onChange={(e) => {
                                                setAdminFirstName(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="lastName"
                                        rules={[{required: true, message: 'Please input last name!'}]}
                                    >
                                        <Input
                                            placeholder="Last Name"
                                            className='input-box'
                                            onChange={(e) => {
                                                setAdminLastName(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="companyName"
                                        rules={[{required: true, message: 'Please input company name!'}]}
                                    >
                                        <Input
                                            placeholder="Company Name"
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="mobileNumber"
                                        rules={[{required: true, message: 'Please input mobile no!'}]}
                                    >
                                        <Input
                                            placeholder="Mobile no."
                                            className='input-box'
                                            onChange={(e) => {

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="email"
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please input email!'
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Please input valid email!'
                                                }
                                            ]
                                        }
                                    >
                                        <Input
                                            placeholder="Email"
                                            className='input-box'
                                            onChange={(e) => {
                                                setAdminEmail(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="password"
                                        rules={[{required: true, message: 'Please input password!'}]}
                                    >
                                        <Input.Password
                                            placeholder="Password"
                                            className='input-box'
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="confirmPassword"
                                        rules={[{required: true, message: 'Please confirm password!'}, { validator: validatePassword }]}
                                    >
                                        <Input.Password
                                            placeholder="Confirm password"
                                            className='input-box'
                                            onChange={(e) => {
                                                setConfirmPassword(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                {
                                    loading ? (
                                        <LoadingSpinner/>
                                    ) : (
                                        <Button block type="primary" htmlType="submit" className="login-form-button">
                                            Update
                                        </Button>
                                    )
                                }
                            </div>
                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                </Form>
            </Container>

            {/*<Modal isOpen={isAddAdminModalVisible} toggle={handleAddAdminOk}>*/}
            {/*    <ModalHeader toggle={handleAddAdminOk}>Confirmation Message</ModalHeader>*/}
            {/*    <ModalBody>*/}
            {/*        <span style={{display: "block"}}>Add New Admin?: </span>*/}
            {/*        <span style={{display: "block"}}>{adminFirstName} {adminLastName}</span>*/}
            {/*        <span style={{display: "block"}}>{adminEmail}</span>*/}
            {/*    </ModalBody>*/}
            {/*    <ModalFooter>*/}
            {/*        <Button danger type="primary" onClick={handleAddAdminOk}>CANCEL</Button>{' '}*/}
            {/*        <Button type="primary" style={{backgroundColor: "#39b64b"}} onClick={onUpdateAdmin}>YES</Button>*/}
            {/*    </ModalFooter>*/}
            {/*</Modal>*/}
        </div>
    )
}

export default UpdateAdmin