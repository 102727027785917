import React, {useEffect, useState} from 'react';
import {Col, Image, Row, Form, Input, Button, Space} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import "../../theme/main.less"
import "./index.css"
import {useNavigate} from "react-router-dom";
import useWindowsDimensionHook from "../../hooks/useWindowsDimensionHook";
import {useCredentialStore} from "../../hooks/useCredentialsStore";
import axios from "axios";
import {PopNotification} from "../../shared/notification";
import {LoadingSpinner} from "../../shared/LoadingSpinner";

const Login: React.FC = ({ children }) => {

    const [UserName, setUserName] = useState("");
    const [Password, setPassword] = useState("");
    const { width, height, isMobile } = useWindowsDimensionHook();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const { credentialUpdate } = useCredentialStore(({ credentialUpdate }) => ({
        credentialUpdate
    }));

    const submitHandler = () => {
        setLoading(true);

        axios
            .post("/api/auth/admin/login", {
                username: UserName, // "admin@rexharge.com",
                password: Password // "Rexharge@123"
            })
            .then((res: { data: { accessToken: string; refreshToken: string; }; }) => {
                PopNotification("success", "Login Successful");
                localStorage.setItem("token", res.data.accessToken);
                credentialUpdate(res.data.accessToken, res.data.refreshToken);
                navigate("/charge-point")
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message ?? "Login Failed");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onClickForgetPassword = () => {
        navigate("/forget-password")
    }

    useEffect(() => {
        if (isMobile) {
            navigate("/mobile")
        }
    }, [width, height])

    return (
        <div className='login-background'>
            <Row>
                <Col span={8} offset={8} className='login-form-container'>
                    <div className='text-center'>
                        <Image
                            src={require("./../../assets/images/brand-name-logo.png")}
                            preview={false}
                        />
                    </div>
                    <span className='login-header'>Login</span>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={submitHandler}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Username"
                                className='input-box'
                                onChange={(e) => {
                                    setUserName(e.target.value);
                                }}
                                autoFocus
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                className='input-box'
                                placeholder="Password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            {
                                loading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Space direction={"vertical"} style={{ width: '100%' }}>
                                        <Button block type="primary" htmlType="submit" className="login-form-button">
                                            Log in
                                        </Button>
                                        <div style={{ textAlign: 'center' }}>
                                            <Button htmlType="button" type={"link"} onClick={() => onClickForgetPassword()}>
                                                Forget Password
                                            </Button>
                                        </div>
                                    </Space>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )

}

export default Login
