import {Button, Form, InputNumber, Modal, Row, Space, Table} from "antd";
import axios from "axios";
import {PopNotification} from "../../../../shared/notification";
import {useCredentialStore} from "../../../../hooks/useCredentialsStore";
import React, {useState} from "react";
import {useForm} from "antd/es/form/Form";
import Text from "antd/es/typography/Text";
import moment from "moment/moment";
import {LoadingSpinner} from "../../../../shared/LoadingSpinner";
import {milliToHHMMSS} from "../../../../utils/dateUtils";


export const UserTransactionActions = ({status, paymentId}: { status: string, paymentId: string }) => {
    let [form] = useForm();
    const [manualCompleteOpen, setManualCompleteOpen] = useState<boolean>(false);
    const [paymentDetailsOpen, setPaymentDetailsOpen] = useState<boolean>(false);
    const [paymentSlipOpen, setPaymentSlipOpen] = useState<boolean>(false);
    const [parkingDataSource, setParkingDataSource] = useState<any>();
    const [chargingDataSource, setChargingDataSource] = useState<any>();
    const [paymentSlip, setPaymentSlip] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false);

    const {token} = useCredentialStore(({token}) => ({
        token
    }));

    const manualComplete = (amount: number) => {
        axios.post(`/api/v2/payment/admin/compensate`, {
            paymentId,
            amount
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            location.reload();
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
        });
    }

    const loadPaymentSummary = () => {
        setLoading(true);
        axios.get(`/api/v2/payment/admin?paymentId=${paymentId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data);
            setParkingDataSource([res.data?.parking]);
            setChargingDataSource(res.data?.chargingSummary.chargingRecords)
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
                setLoading(false);
            }
        )
    }

    const loadPaymentSlip = () => {
        setLoading(true);
        axios.get(`/api/v2/payment/slip/admin?paymentId=${paymentId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data);
            setPaymentSlip(res.data)
        }).catch((err) => {
            PopNotification("error", err?.response?.data?.message || `Something went wrong, ${err.toString()}`);
        }).finally(() => {
                setLoading(false);
            }
        )
    }

    const parkingColumns = [
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text: string) => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (text: string) => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => <span>{text ?? "-"}</span>
        },
    ]


    const chargingColumns = [
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text: string) => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (text: string) => <span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
        },
        {
            title: 'Start Meter Value',
            dataIndex: 'startMeterValue',
            key: 'startMeterValue'
        },
        {
            title: 'End Meter Value',
            dataIndex: 'endMeterValue',
            key: 'endMeterValue',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => <span>{text ?? "-"}</span>
        },
    ]


    const showActions = (status: string) => {
        if (status === 'MANUAL_COMPLETE' || status === 'PAYMENT_CHARGED') {
            return <>
                <Button onClick={() => {
                    loadPaymentSummary();
                    setPaymentDetailsOpen(true);
                }}>Details</Button>

                <Button onClick={() => {
                    loadPaymentSlip();
                    setPaymentSlipOpen(true);
                }}>Payment Slip</Button>
            </>
        }

        return <>
            <Button onClick={() => {
                setManualCompleteOpen(true)
            }}>Manual Complete</Button>
            <Button onClick={() => {
                loadPaymentSummary();
                setPaymentDetailsOpen(true);
            }}>Details</Button>
        </>
    }

    return <>
        {showActions(status)}
        <Modal title="Manual Compensate" visible={manualCompleteOpen} onOk={() => {
            manualComplete(form.getFieldValue('amount'));
        }} onCancel={() => setManualCompleteOpen(false)}>
            <Form
                form={form}
                name="manualCompensate"
                autoComplete="off"
            >
                <Form.Item
                    label="Amount"
                    name="amount"
                    initialValue={0}
                    rules={[{required: true, message: 'Please input the amount!'}]}
                >
                    <InputNumber/>
                </Form.Item>
            </Form>
        </Modal>

        <Modal title="Payment Details" visible={paymentDetailsOpen} onOk={() => {
            setPaymentDetailsOpen(false)
        }} onCancel={() => {
            setPaymentDetailsOpen(false)
        }}>
            <Text>Parking</Text>
            <Table
                columns={parkingColumns}
                dataSource={parkingDataSource}
                loading={loading}
                pagination={false}
            />
            <Text>Charging</Text>
            <Table
                columns={chargingColumns}
                dataSource={chargingDataSource}
                loading={loading}
                pagination={false}
            />
        </Modal>


        <Modal title="PaymentSlip" visible={paymentSlipOpen} onOk={() => {
            setPaymentSlipOpen(false);
        }} onCancel={() => setPaymentSlipOpen(false)}>
            {loading ? <LoadingSpinner/> :
                <>
                    <Row>
                        <Text>Charge Point Name: {paymentSlip?.chargePointName}</Text>
                    </Row>
                    <Row>
                        <Text>Charger Id: {paymentSlip?.chargerUniqueId}</Text>
                    </Row>
                    <Row>
                        <Text>Charging Time: {milliToHHMMSS(paymentSlip?.timeElapsed)}</Text>
                    </Row>
                    <Row>
                        <Text>Energy Charged: {paymentSlip?.totalCharged.toFixed(2)}kWh</Text>
                    </Row>
                    <Row>
                        <Text>Charge Calculation Method: {paymentSlip?.chargeCalculationMethod}</Text>
                    </Row>
                    <Row>
                        <Text>Charge Unit Amount: RM{paymentSlip?.chargeUnitAmount?.toFixed(2)?? '0.00'}/{paymentSlip?.chargeCalculationMethod === 'TARIFF'? 'kWh' : 'min'}</Text>
                    </Row>
                    <Row>
                        <Text>Idle Time: {milliToHHMMSS(paymentSlip?.idleTime)}</Text>
                    </Row>
                    <Row>
                        <Text>Idle Fee: RM{paymentSlip?.idleFee?? '0.00'}/min</Text>
                    </Row>
                    <Row>
                        <Text>Discount: RM{paymentSlip?.discount.toFixed(2)}</Text>
                    </Row>
                    <Row>
                        <Text>E-Coin Deduction: RM{paymentSlip?.ecoinDeduction.toFixed(2)}</Text>
                    </Row>
                    <Row>
                        <Text>Total: RM{paymentSlip?.total.toFixed(2)}</Text>
                    </Row>
                    <Row>
                        <Text>Grant Total: RM{paymentSlip?.grantTotal.toFixed(2)}</Text>
                    </Row>
                </>
            }
        </Modal>
    </>
}