import React, {useEffect, useState} from "react";
import {
    addChargerProps, chargerBody, chargerListType, chargerSocketBody, chargeSocketListType,
    getChargePointBasedOnTextSearchBody, getChargePointBasedOnTextSearchResponse,
    getModelsBody, getModelsResponse,
    getSocketTypeBody, getSocketTypeResponse,
    getVendorBody, getVendorResponse, registerCarBarrier, socketToShowBody,
    socketToSubmitBody
} from "../../types/admin";
import {Button, Form, Image, Input as AntInput, Select, Upload} from "antd";
import {useCredentialStore} from "../../hooks/useCredentialsStore";
import axios from "axios";
import {PopNotification} from "../../shared/notification";
import {v4 as uuidv4} from "uuid";
import {Col, Container, Input, Row, Table} from "reactstrap";
import {getBase64} from "../../utils/image";
import {LoadingSpinner} from "../../shared/LoadingSpinner";
import {getChargerProps, socketProps} from "../../types/superAdmin";

export const CarBarrierPage = () => {

    const [uniqueId, setUniqueId] = useState("");
    // const [profileImageBase64, setProfileImageBase64] = useState("");
    const [profileImageId, setProfileImageId] = useState<string | null>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedChargePointId, setSelectedChargePointId] = useState("");
    const [selectedChargerId, setSelectedChargerId] = useState("");
    const [selectedSocketId, setSelectedSocketId] = useState("");
    const [chargePointLoading, setChargePointLoading] = useState<boolean>(false);
    const [mainChargePointList, setMainChargePointList] = useState<getChargePointBasedOnTextSearchBody[]>([])
    const [mainChargerList, setMainChargerList] = useState<chargerBody[]>([])
    const [mainSocketList, setMainSocketList] = useState<socketProps[]>([])
    const [form] = Form.useForm();
    const {token} = useCredentialStore(({token}) => ({
        token
    }));
    const {Option} = Select;

    const onSearch = (value: string) => {
        setChargePointLoading(true);
        axios
            .get((value !== "" || value != null || value !== undefined) ? `/api/chargePoints?page=0&pageSize=100&textSearch=${value}` : (`/api/chargePoints?page=0&pageSize=10`), {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargePointList: getChargePointBasedOnTextSearchResponse = res.data;
                setMainChargePointList(chargePointList.data);
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                setChargePointLoading(false);
            });
    };

    const onAddBarrier = () => {
        form.validateFields().then(values => {
            setLoading(true);

            const dataToSubmit: registerCarBarrier = {
                downLinkAckTopic: values.downLinkAckTopic,
                downLinkTopic: values.downLinkTopic,
                heartbeatTopic: values.heartbeatTopic,
                upLinkTopic: values.upLinkTopic,
                gatewayId: values.gatewayId,
                deviceId: values.deviceId,
                password: values.password,
                socketId: selectedSocketId
            }

            axios
                .post("/api/carBarrier", dataToSubmit, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    PopNotification("success", "Car Barrier Created Successfully");
                })
                .catch((error) => {
                    PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }

    const onGetSocketList = () => {

        axios
            .get(`/api/chargerWithSockets/${selectedChargerId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const socketList: getChargerProps = res.data;
                setMainSocketList(socketList.socketList);
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                setChargePointLoading(false);
            });
    }

    const onGetChargerList = () => {
        axios
            .get(`/api/chargePoint/${selectedChargePointId}/chargerSocketStatusSummary?pageSize=999&page=0`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargerList: chargerListType = res.data;
                setMainChargerList(chargerList.data);
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                setChargePointLoading(false);
            });
    }

    const onGetChargePoint = () => {
        axios
            .get(`/api/chargePoints?page=0&pageSize=999`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargePointList: getChargePointBasedOnTextSearchResponse = res.data;
                console.log(chargePointList.data);
                setMainChargePointList(chargePointList.data);
            })
            .catch((error) => {
                PopNotification("error", error || error?.response?.data?.message || `Something went wrong, ${error.toString()}`);
            })
            .finally(() => {
                setChargePointLoading(false);
            });
    }

    useEffect(() => {
        onGetChargePoint();
    }, []);

    useEffect(() => {
        if (selectedChargerId !== "") {
            onGetSocketList();
        }
    }, [selectedChargerId]);

    useEffect(() => {
        if (selectedChargePointId !== "") {
            onGetChargerList();
        }
    }, [selectedChargePointId]);

    return (
        <div>
            <span className='page-title'>Add Car Barrier</span>
            <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                <Form form={form}
                      layout="vertical"
                      onFinish={onAddBarrier}>
                    <Row>
                        <Col md={6}>
                            <div style={{marginTop: 20,}}>
                                { uniqueId !== "" && <span className='id-css'>Unique ID: {uniqueId}</span> }
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="name"
                                    >
                                        <Input
                                            placeholder="Charge Point"
                                            className='input-box'
                                            onChange={(e) => {
                                                onSearch(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <span className='id-css'>Selected Charge Point: {selectedChargePointId}</span>

                                <div style={{margin: "5px 13px"}}>
                                    <Row md={12} style={{marginTop: 10}}>
                                        <Col md={12} className='card-datavisulization'
                                             style={{textAlign: "left", padding: 20, height: "auto"}}>
                                            {
                                                chargePointLoading ? (
                                                    <LoadingSpinner/>
                                                ) : (
                                                    <Table bordered responsive>
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                Charge Point
                                                            </th>
                                                            <th>
                                                                Address
                                                            </th>
                                                            <th>
                                                                Access
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            mainChargePointList?.map((chargePointDetails) => (
                                                                <tr>
                                                                    <td>
                                                                        {chargePointDetails.name}
                                                                    </td>
                                                                    <td>
                                                                        {chargePointDetails.name} {chargePointDetails.name}
                                                                    </td>
                                                                    <td style={{textAlign: "center"}}>
                                                                        <Button block type="primary" htmlType="button"
                                                                                className={"charger-assign-button"}
                                                                                onClick={() => setSelectedChargePointId(chargePointDetails.id.id)}>
                                                                            Assign Access
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </Table>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="chargerId"
                                        label={"Charger"}
                                    >
                                        <Select onChange={(value) => {
                                            setSelectedChargerId(value)
                                        }}>
                                            {
                                                mainChargerList?.map((chargerDetails: chargerBody) => {
                                                    return (
                                                        <Select.Option key={chargerDetails.id.id} value={chargerDetails.id.id}>{chargerDetails.uniqueId}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <span className='id-css'>Selected Charger: {selectedChargerId}</span>

                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="socketId"
                                        label={"Socket"}
                                    >
                                        <Select onChange={(value) => setSelectedSocketId(value)}>
                                            {
                                                mainSocketList?.map((socket: socketProps) => {
                                                    return (
                                                        <Select.Option key={socket.id.id} value={socket.id.id}>{ socket.currentType }</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <span className='id-css'>Selected Socket: {selectedSocketId}</span>

                                {
                                    selectedSocketId !== "" && (
                                        <>
                                            <span className='heading-page'>Car Barrier Details</span>
                                            <div style={{margin: "10px 0px",}}>
                                                <Form.Item name="deviceId">
                                                    <AntInput placeholder='Device Id'/>
                                                </Form.Item>
                                            </div>
                                            <div style={{margin: "10px 0px",}}>
                                                <Form.Item name="password">
                                                    <AntInput placeholder='Password'/>
                                                </Form.Item>
                                            </div>
                                            <div style={{margin: "10px 0px",}}>
                                                <Form.Item name="gatewayId">
                                                    <AntInput placeholder='Gateway Id'/>
                                                </Form.Item>
                                            </div>
                                            <div style={{margin: "10px 0px",}}>
                                                <Form.Item name="heartbeatTopic">
                                                    <AntInput placeholder='Heart Beat Topic'/>
                                                </Form.Item>
                                            </div>
                                            <div style={{margin: "10px 0px",}}>
                                                <Form.Item name="downLinkAckTopic">
                                                    <AntInput placeholder='Down Link Ack Topic'/>
                                                </Form.Item>
                                            </div>
                                            <div style={{margin: "10px 0px",}}>
                                                <Form.Item name="downLinkTopic">
                                                    <AntInput placeholder='Down Link Topic'/>
                                                </Form.Item>
                                            </div>
                                            <div style={{margin: "10px 0px",}}>
                                                <Form.Item name="upLinkTopic">
                                                    <AntInput placeholder='Up Link Topic'/>
                                                </Form.Item>
                                            </div>

                                            {
                                                loading ? (
                                                    <LoadingSpinner />
                                                ) : (
                                                    <Button block type="primary" htmlType="submit" className="login-form-button">
                                                        Add Car Barrier
                                                    </Button>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    )
}
